// colors
@import '../../../src/variables.scss';

.admin-panel-body {
    margin-top: 30px;
    position: relative;
    padding-left: 250px
}

/* admin sidebar */
.admin-sidebar {
    position: absolute;
    width: 250px;
    left: 0;
    top: 0;
    height: 100%;

    @media only screen and (min-width:992px) {
        background: #fff;
    }

    .dashboard-nav {
        padding: 0;
        height: 100%;
    }
}

.sidebar-wrap {
    height: 100%;
}

.dashboard-nav {
    .navbar-collapse {
        background: #fff;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
        display: block !important;
        height: 100%;
        overflow: hidden;

        @media only screen and (min-width:992px) {
            overflow: visible;
        }

        li {
            white-space: nowrap;
            border-bottom: 1px solid $color-grey;
            cursor: pointer;

            &:last-child {
                border-bottom: 0;
            }

            a {
                padding-left: 25px;
                padding-right: 15px;
                font-size: 15px;
                color: #5E5E5E;

                &:hover {
                    background: $color-grey;
                }
            }

            &.active {
                a {
                    background: darken($color-grey, 5%);
                }

                border-bottom: 1px solid transparent
            }
        }
    }
}

.admin-sidebar .sidebar-toggle {
    display: none;

    &:focus {
        box-shadow: none;
    }
}

.sidebar-wrap .sidebar-toggle-close {
    display: none;

    &:focus {
        box-shadow: none;
    }
}

/* dashboard content right */

.admin-panel-content-wrap.main-site-content {
    padding-top: 0
}

.ap-container {
    padding: 0 30px
}

.admin-table {
    border-left: 1px solid #E7E7E7;
    border-top: 3px solid #20C2E4;

    padding: 5px;

    table {

        th {
            font-weight: 400;
            font-size: 16px
        }

        td {
            white-space: nowrap;
        }
    }

    .list-hz {
        background: #fff;
        -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
        -webkit-border-radius: 10px;
        border-radius: 10px;
        padding: 0;

        li {
            display: inline-block;
            padding: 5px
        }
    }

    .table-hover tbody {
        tr {
            border-radius: 6px;
        }

        tr:hover {
            background: #fff;
            -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
            overflow: hidden;
            border-radius: 6px !important;

            td:first-child {
                border-top-left-radius: 6px;
                border-bottom-left-radius: 6px;
            }

            td:last-child {
                border-top-right-radius: 6px;
                border-bottom-right-radius: 6px;
            }
        }

    }

    .btn-primary,
    .btn-status {
        font-size: 14px;
        width: 110px;
        padding-left: 5px;
        padding-right: 5px;
    }
}

.admin-panel-search {
    &.search-wrap {
        margin-bottom: 0;

        .form-control {
            height: calc(1.5em + .75rem + -4px);
            background-position: 15px 9px;
            max-width: 200px
        }
    }
}

/* Media  */

@media only screen and (max-width:993px) {
    .admin-panel-body {
        .sidebar-wrap {
            width: 0;
            -webkit-transition: .5s;
            -ms-transition: .5s;
            transition: .5s;
            position: relative;
            z-index: 2;
            background: #fff;
        }

        .navbar-collapse {
            padding-top: 10px;
            padding-bottom: 10px
        }

        .sidebar-toggle-close {
            display: none
        }

        &.nav-open .sidebar-wrap {
            width: 240px;

            .sidebar-toggle-close {
                display: block
            }
        }

        .sidebar-toggle-close {
            position: absolute;
            right: -5px;
            top: -3px;
            color: $color-light-grey;
            z-index: 4;
        }
    }

    .admin-panel-body {
        .admin-panel-content-wrap {
            margin-left: 0;
            -webkit-transition: margin-left .5s;
            -ms-transition: margin-left .5s;
            transition: margin-left .5s;
        }

        &.nav-open {
            .admin-panel-content-wrap {
                position: relative;
                margin-left: 250px
            }
        }
    }

    .admin-panel-body {
        padding-left: 0
    }

    .admin-sidebar .sidebar-toggle {
        display: block;
        font-size: 18px;
        position: absolute;
        z-index: 1;
        width: 40px;
        height: 40px;
        top: 16px;
        left: 15px;
        color: $color-light-grey
    }

    .ap-container {
        padding-left: 15px;
        padding-right: 15px;
    }
}

.subscripition {
    .card-container {
        // margin-right: 55px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.50);

        @media screen and (max-width: 767px) {
            margin-bottom: 40px;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    .block-heading {
        font-size: 18px;
        margin-bottom: 20px;
        display: inline-block;
        font-weight: bold;
        color: #5E5E5E;

        @media screen and (max-width: 992px) {
            width: 100%;
            text-align: center;
        }
    }

    .content {
        margin-bottom: 35px;

        .heading {
            span {
                font-size: 24px;
                color: #5E5E5E;
                font-weight: bold;
            }
        }

        .value {
            span {
                font-size: 18px;
                color: #5E5E5E;
                font-weight: bold;
                opacity: .75;
            }
        }
    }

    .sub-content {
        margin-bottom: 35px;
        min-height: 75px;

        @media screen and (max-width: 767px) {
            min-height: auto;
        }

        .col-sm-12 {
            @media screen and (max-width: 767px) {
                margin-bottom: 40px;
            }
        }

        .heading-value {
            min-height: 75px;
        }

        .heading {
            span {
                font-size: 16px;
                color: #5E5E5E;
                font-weight: bold;

                @media screen and (max-width: 991px) {
                    font-size: 14px;
                }
            }
        }

        .value {
            font-size: 16px;
            color: #5E5E5E;
            font-weight: bold;
        }
    }

    .edit-btn {
        background: #D66868;
        color: #fff;
        width: 87px;
        text-align: center;
        border-radius: 8px;
        margin-bottom: 10px;

        @media screen and (max-width: 1150px) and (min-width: 768px) {
            width: 74px;
        }

        &:hover {
            color: #fff;
        }
    }

    .btn-danger {
        margin-right: 15px;
        float: left;
    }

    .btn-btn-status,
    .btn-success {
        float: right;
    }

}

.metered-subscriptions {
    margin-top: 40px;

    .sub-content {
        text-align: left;

        .list {
            margin-bottom: 30px;

            &:last-child {
                margin-bottom: 0;
            }

            span {
                font-size: 16px;
                color: #5E5E5E;
                font-weight: bold;

                &:first-child {
                    width: 100px;
                    display: inline-block;
                }
            }
        }
    }

    .card-container {
        min-height: 285px;
    }
}

.blue-gray-border {
    border-left: 1px solid #E7E7E7;
    border-top: 3px solid #20C2E4;
    padding: 15px;
}
