// Influencer Ignite Japan Theme
// =================================

// colors
$primary-theme-color: #ff3366;
$primary-theme-color-50: #ff336680;
$primary-theme-color-70: #ff3366b3;
$primary-theme-color-transparent: #ff33661c;

$tooltip-opacity: 0;
$logo_size: 40px;
$top_logo: 60px;

$audience-badge-content: "オーディエンス";
$influencer-badge-content: "インフルエンサー";

// Buttons
$min-filter-button-width: 200px;

$primary-button-bg-color: $primary-theme-color;
$primary-button-shadow: 0px 3px 10px rgba(255, 51, 71, 0.32);

$primary-button-outline-color: $primary-theme-color;

$primary-button-hover-outline-color: rgb(245, 90, 90);
$primary-button-hover-outline-shadow: 0px 3px 10px rgba(245, 90, 90, 0.32);

$primary-button-active-outline-color: #ff333d;
$primary-button-active-outline-shadow: 0px 3px 10px rgba(255, 51, 61, 0.4);
