// Variables
// =================================
@import "../../../color-palette";
@import "../../../mixins";

.link-more {
  color: $primary-theme-color;

  &:hover {
    color: $primary-theme-color;
  }
}

// headings
// ==================================

h1,
h2,
h3 {
  font-family: $font-grotesk-bold;
}

// Content
// ==================================

.dash-search {

	padding: 15px 15px 8px;

	.sub-text {
		@include theme-aware("color", "title");
		font-size: 20px;
		font-family: $font-grotesk-medium;
	}

	.dropdown {
		@include theme-aware("box-shadow", "shadow");

		input {
			@include theme-aware("color", "icon-text");
			@include theme-aware("background-color", "table-background");
			width: 500px;
			border: none;

			&:focus {
				border: 1px solid #00c2e6;
			}
		}

		::placeholder {
			color: $color-xlight-grey;
			font-size: 14px;
		}

		:-ms-input-placeholder {
			color: $color-xlight-grey;
		}

		::-ms-input-placeholder {
			color: $color-xlight-grey;
		}
	}

	.creators-text {

		margin-top: 8px;
		font-size: 14px;

		.discovery-link {
			color: $primary-theme-color;
			font-weight: bold;

			&:hover {
				color: $primary-theme-color;
				// text-decoration: none;
			}
		}
	}
}
