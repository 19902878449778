// Variables
// =================================
@import "../../../color-palette";
@import "../../../mixins";

.volume-icon {
  position: absolute;
  top: 10px;

  @include theme-aware("background-color", "table-background");
  @include theme-aware("box-shadow", "shadow-dark");
  padding: 10px;
  border-radius: 50%;

  &.muted {
    color: $color-red;
  }

  &.active {
    color: $color-light-grey;
  }
}
