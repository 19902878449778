// Variables
// =================================
@import "../../color-palette";
@import "../../mixins";

// Content
// ==================================

.global-loading {
	position: fixed;
	left: 0;
	z-index: 1150;
}
