/**
 * theme-aware - Change color of a css property based on the currently active theme
 *
 * @param  {key}     CSS property
 * @param  {color}   Color name defined in the themes under _color-palette.scss
 *
 * @example - @include theme-aware('background', 'color-background');
 * @returns - background: #FFF;
 */

@mixin theme-aware($key, $color) {
  @each $theme-name, $theme-color in $themes {
    .theme-#{$theme-name} & {
      #{$key}: map-get(map-get($themes, $theme-name), $color);
      @include transition(#{$key} 500ms);
    }
  }
}

@mixin transition($transition...) {
  -moz-transition: $transition;
  -o-transition: $transition;
  -webkit-transition: $transition;
  transition: $transition;
}
