// Variables
// =================================
@import "color-palette";
@import "mixins";

body {
  overflow-x: hidden;
  height: 100%;
}

html {
  height: 100%;
}

#root {
  height: 100%;
}

.footer-container {
  margin-top: auto;
  margin-left: 300px;
  margin-right: 300px;

  @media (max-width: 1000px) {
    margin-right: 0px;
  }

  @media (max-width: $mobile-width) {
    margin-left: 0px;
  }
}

.app {
  @include theme-aware("background-color", "color-background");
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: 0;
  min-height: 100%;
  position: relative;
  transition: margin 0.3s ease-out 0s;
  overflow: hidden;
}

#app {
  @media (max-width: $mobile-width) {
    padding-left: 0px !important;
  }
}

.color-blue {
  color: $primary-theme-color;
}

.subs-btn {
  width: 80% !important;
  margin: 0 17px;
}

.minWidth-200 {
  min-width: 200px !important;
}

.min-height-100 {
  min-height: 100px !important;
}

// Margins

.m-top0 {
  margin-top: 0;
}

.mg-left-15 {
  margin-left: 15px;
}

.m-bottom0 {
  margin-bottom: 0;
}

.mb-40 {
  margin-bottom: 40px;
}

.ml-380 {
  margin-left: 380px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-45 {
  margin-top: 45px;
}

.ml-80 {
  margin-left: 80px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-30 {
  margin-right: 30px;
}

// Paddings
.ptop0 {
  padding-top: 0px !important;
}

.subscribe-button-padding {
  padding: 0.65rem 0.5rem !important;
}

.ptop10 {
  padding-top: 10px;
}

.pt-50 {
  padding-top: 50px;
}

.pt-90 {
  padding-top: 90px;
}

.pt-100 {
  padding-top: 100px;
}

.pt-40 {
  padding-top: 40px;
}

.ptop15 {
  padding-top: 10px;
}

.margin-top-20 {
  margin-top: 20px;
}

.p0 {
  padding: 0px;
}

// Global elements customizaation
.transparent-btn {
  background: none;
  color: #03c2e6;
  border: 0;
  padding: 0;
  margin-bottom: 15px;
}

.transparent-btn:hover,
.transparent-btn:focus {
  background: none;
  box-shadow: none;
  color: #03c2e6;
}

.btn-primary.focus,
.btn-primary:focus {
  box-shadow: none !important;
}

.form-control {
  @include theme-aware("background-color", "dropdown-background");
  @include theme-aware("border-color", "dropdown-hover");
  @include theme-aware("color", "title");
  border: solid 1.5px;
  height: 30px;
}

.form-control:focus {
  border-color: $primary-theme-color;

  @include box-shadow(none);
}

.form-group {
  input {
    font-family: $font-grotesk-Regular;
    font-size: $font-size-default;
  }

  margin: 0;
}

// Buttons
.btn {
  font-size: 16px;
  font-family: $font-grotesk-Regular;

  @include border-radius($blocks-radius);
}

.btn-default {
  @include theme-aware("box-shadow", "shadow");
  background: #fff;
}

// btn primary
.btn.btn-primary {
  color: #fff;
  border: 0;
  background: $primary-button-bg-color;
  border-radius: 0.25rem;

  @include box-shadow($primary-button-shadow);

  &:focus,
  &.focus,
  &:hover {
    background: $primary-button-hover-outline-color;
    border: 0;
    color: $color-white;
  }

  &:active,
  &.active,
  &:not(:disabled):not(.disabled):active {
    background: $primary-button-hover-outline-color;
    border: 0;
    color: $color-white;
  }
}

.btn.btn-outline-primary {
  color: $primary-theme-color;
  border-color: $primary-theme-color;
  background: $color-white;

  @include theme-aware("background", "modal-background");
  border-radius: 0.25rem;

  &:hover {
    background: $primary-button-hover-outline-color !important;
    color: #fff !important;
    border-color: $primary-button-hover-outline-color !important;

    @include box-shadow($primary-button-hover-outline-shadow);
  }

  &:focus,
  &:active,
  &:visited,
  &:not(:disabled):not(.disabled):active {
    @include theme-aware("background", "modal-background");
    border-color: $primary-button-active-outline-color;

    @include box-shadow($primary-button-active-outline-shadow);
  }

  &:disabled,
  &.disabled {
    background-color: $grey-disabled !important;
    box-shadow: none !important;
    border: none;
  }
}

// btn success

.btn.btn-success {
  color: #fff;
  border: 0;
  background: $success-button-bg-color;

  @include box-shadow($success-button-shadow !important);

  &:focus,
  &.focus,
  &:hover {
    background: rgba(48, 198, 46, 1);
    background: -moz-linear-gradient(
      top,
      rgba(48, 198, 46, 1) 0%,
      rgba(53, 213, 50, 1) 100%
    );
    background: -webkit-gradient(
      left top,
      left bottom,
      color-stop(0%, rgba(48, 198, 46, 1)),
      color-stop(100%, rgba(53, 213, 50, 1))
    );
    background: -webkit-linear-gradient(
      top,
      rgba(48, 198, 46, 1) 0%,
      rgba(53, 213, 50, 1) 100%
    );
    background: -o-linear-gradient(
      top,
      rgba(48, 198, 46, 1) 0%,
      rgba(53, 213, 50, 1) 100%
    );
    background: -ms-linear-gradient(
      top,
      rgba(48, 198, 46, 1) 0%,
      rgba(53, 213, 50, 1) 100%
    );
    background: linear-gradient(
      to bottom,
      rgba(48, 198, 46, 1) 0%,
      rgba(53, 213, 50, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#30c62e', endColorstr='#35d532', GradientType=0);
    border: 0;
    color: $color-white;
  }

  &:active,
  &.active,
  &:not(:disabled):not(.disabled):active {
    background: rgba(48, 198, 46, 1);
    background: -moz-linear-gradient(
      top,
      rgba(48, 198, 46, 1) 0%,
      rgba(45, 165, 43, 1) 100%
    );
    background: -webkit-gradient(
      left top,
      left bottom,
      color-stop(0%, rgba(48, 198, 46, 1)),
      color-stop(100%, rgba(45, 165, 43, 1))
    );
    background: -webkit-linear-gradient(
      top,
      rgba(48, 198, 46, 1) 0%,
      rgba(45, 165, 43, 1) 100%
    );
    background: -o-linear-gradient(
      top,
      rgba(48, 198, 46, 1) 0%,
      rgba(45, 165, 43, 1) 100%
    );
    background: -ms-linear-gradient(
      top,
      rgba(48, 198, 46, 1) 0%,
      rgba(45, 165, 43, 1) 100%
    );
    background: linear-gradient(
      to bottom,
      rgba(48, 198, 46, 1) 0%,
      rgba(45, 165, 43, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#30c62e', endColorstr='#2da52b', GradientType=0);
    border: 0;
    color: $color-white;
  }
}

// btn danger

.btn.btn-danger {
  color: #fff;
  border: 0;
  background: $danger-button-bg-color;

  @include box-shadow($danger-button-shadow !important);

  &:focus,
  &.focus,
  &:hover {
    background: rgba(242, 103, 101, 1);
    background: -moz-linear-gradient(
      top,
      rgba(242, 103, 101, 1) 0%,
      rgba(246, 141, 141, 1) 100%
    );
    background: -webkit-gradient(
      left top,
      left bottom,
      color-stop(0%, rgba(242, 103, 101, 1)),
      color-stop(100%, rgba(246, 141, 141, 1))
    );
    background: -webkit-linear-gradient(
      top,
      rgba(242, 103, 101, 1) 0%,
      rgba(246, 141, 141, 1) 100%
    );
    background: -o-linear-gradient(
      top,
      rgba(242, 103, 101, 1) 0%,
      rgba(246, 141, 141, 1) 100%
    );
    background: -ms-linear-gradient(
      top,
      rgba(242, 103, 101, 1) 0%,
      rgba(246, 141, 141, 1) 100%
    );
    background: linear-gradient(
      to bottom,
      rgba(242, 103, 101, 1) 0%,
      rgba(246, 141, 141, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f26765', endColorstr='#f68d8d', GradientType=0);
    border: 0;
    color: $color-white;
  }

  &:active,
  &.active,
  &:not(:disabled):not(.disabled):active {
    background: rgba(242, 103, 101, 1);
    background: -moz-linear-gradient(
      top,
      rgba(242, 103, 101, 1) 0%,
      rgba(213, 95, 93, 1) 100%
    );
    background: -webkit-gradient(
      left top,
      left bottom,
      color-stop(0%, rgba(242, 103, 101, 1)),
      color-stop(100%, rgba(213, 95, 93, 1))
    );
    background: -webkit-linear-gradient(
      top,
      rgba(242, 103, 101, 1) 0%,
      rgba(213, 95, 93, 1) 100%
    );
    background: -o-linear-gradient(
      top,
      rgba(242, 103, 101, 1) 0%,
      rgba(213, 95, 93, 1) 100%
    );
    background: -ms-linear-gradient(
      top,
      rgba(242, 103, 101, 1) 0%,
      rgba(213, 95, 93, 1) 100%
    );
    background: linear-gradient(
      to bottom,
      rgba(242, 103, 101, 1) 0%,
      rgba(213, 95, 93, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f26765', endColorstr='#d55f5d', GradientType=0);
    border: 0;
    color: $color-white;
  }
}

// btn danger

.btn.btn-warning {
  color: #fff;
  border: 0;
  background: $warning-button-bg-color;

  //@include box-shadow($danger-button-shadow !important);

  &:focus,
  &.focus,
  &:hover {
    background: -moz-linear-gradient(
      280deg,
      rgba(245, 166, 35, 1) 0%,
      rgba(245, 197, 42, 1) 100%
    );

    /* ff3.6+ */
    background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      color-stop(0%, rgba(245, 166, 35, 1)),
      color-stop(100%, rgba(245, 197, 42, 1))
    );

    /* safari4+,chrome */
    background: -webkit-linear-gradient(
      280deg,
      rgba(245, 166, 35, 1) 0%,
      rgba(245, 197, 42, 1) 100%
    );

    /* safari5.1+,chrome10+ */
    background: -o-linear-gradient(
      280deg,
      rgba(245, 166, 35, 1) 0%,
      rgba(245, 197, 42, 1) 100%
    );

    /* opera 11.10+ */
    background: -ms-linear-gradient(
      280deg,
      rgba(245, 166, 35, 1) 0%,
      rgba(245, 197, 42, 1) 100%
    );

    /* ie10+ */
    background: linear-gradient(
      170deg,
      rgba(245, 166, 35, 1) 0%,
      rgba(245, 197, 42, 1) 100%
    );

    /* w3c */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f5a623', endColorstr='#F5C52A', GradientType=0);

    /* ie6-9 */
    border: 0;
    color: $color-white;
  }

  &:active,
  &.active,
  &:not(:disabled):not(.disabled):active {
    background: -moz-linear-gradient(
      280deg,
      rgba(245, 166, 35, 1) 0%,
      rgba(245, 131, 0, 1) 100%
    );

    /* ff3.6+ */
    background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      color-stop(0%, rgba(245, 166, 35, 1)),
      color-stop(100%, rgba(245, 131, 0, 1))
    );

    /* safari4+,chrome */
    background: -webkit-linear-gradient(
      280deg,
      rgba(245, 166, 35, 1) 0%,
      rgba(245, 131, 0, 1) 100%
    );

    /* safari5.1+,chrome10+ */
    background: -o-linear-gradient(
      280deg,
      rgba(245, 166, 35, 1) 0%,
      rgba(245, 131, 0, 1) 100%
    );

    /* opera 11.10+ */
    background: -ms-linear-gradient(
      280deg,
      rgba(245, 166, 35, 1) 0%,
      rgba(245, 131, 0, 1) 100%
    );

    /* ie10+ */
    background: linear-gradient(
      170deg,
      rgba(245, 166, 35, 1) 0%,
      rgba(245, 131, 0, 1) 100%
    );

    /* w3c */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f5a623', endColorstr='#F58300', GradientType=0);

    /* ie6-9 */
    border: 0;
    color: $color-white;
  }
}

// btn dropdown
.btn.btn-dropdown {
  color: #818181;
  border: 0;
  background: transparent;
}

.cancel-subs-btn.btn-secondary:not(:disabled):not(.disabled):active,
.cancel-subs-btn:focus,
.cancel-subs-btn:hover {
  color: #e23b39 !important;
}

.btn-secondary.focus,
.btn-secondary:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: none;
}

.switch-text.btn-secondary:not(:disabled):not(.disabled):active {
  color: #0f940d;
}

.show > .btn-outline-primary.dropdown-toggle {
  background: #fff;
  color: inherit;
  border-color: #00c2e6;
}

.show > .btn-outline-primary.dropdown-toggle:focus {
  @include box-shadow($primary-button-hover-outline-shadow);
}

.btn.btn-outline-dark {
  color: $btn-outline-dark;
  border-color: $btn-outline-dark;

  &:focus,
  &.focus,
  &:hover,
  &:active,
  &.active {
    background: $btn-outline-dark;
    border-color: $btn-outline-dark;
    color: $color-white;

    @include box-shadow(none !important);
  }
}

.btn-secondary {
  &:not(:disabled):not(.disabled).active,
  &:not(:disabled):not(.disabled):active {
    color: #00c2e6;
    background-color: transparent;
    border-color: transparent;
  }
}

.show > .btn-secondary.dropdown-toggle {
  color: #00c2e6;
  background-color: transparent;
  border-color: transparent;
}

.btn.btn-link {
  @include theme-aware("color", "title");
  font-size: 14px;
}

button.dropdown-toggle {
  &:hover {
    text-decoration: none;
  }
}

.button-dropdown-primary-test.dropdown-menu {
  background: $primary-button-bg-color;
  border-radius: 0.25rem;
  background: $color-white;
  overflow: hidden;

  .dropdown-item {
    @include theme-aware("color", "icon-text");

    &:hover,
    &:focus,
    &:active,
    &:visited,
    &:not(:disabled):not(.disabled):active {
      @include theme-aware("background", "dropdown-hover");
    }
  }
}

.btn-with-caret {
  min-width: 100px;
  padding-right: 11px;
  padding-left: 11px;
  height: 41px;
  text-align: left;

  &:after {
    border: 0;
    background: url(./img/caret-down.svg) no-repeat 0 0;
    width: 12px;
    height: 8px;
    vertical-align: middle;
    margin-left: 3px;
    margin-top: 9px;
    background-size: 15px 5px;
    float: right;
  }
}

a.link {
  color: $primary-theme-color;

  &:hover,
  &:focus,
  &:active {
    color: $primary-theme-color;
  }
}

// .error {
// border-color: #dc3545;
// }
// Icons
.icon {
  display: inline-block;
}

.other-filter {
  margin-left: 5px;
  top: 3px !important;
}

.tooltip-icon {
  @include theme-aware("background-color", "tooltip-background");
  opacity: $tooltip-opacity;
  -webkit-mask: url(./img/icon-qus.svg) no-repeat 0 0;
  mask: url(./img/icon-qus.svg) no-repeat 0 0;
  -webkit-mask-size: cover;
  mask-size: cover;
  min-width: 16px;
  min-height: 16px;
  display: inline-block;
  background-size: 15px;
  position: relative;
  top: 3px;

  &:hover {
    opacity: $tooltip-opacity - 0.1;
  }
}

.tooltip-inner {
  font-size: 12px;
  background-color: $dark-mode-background-color;
  color: $color-grey;
}

.tooltip {
  .arrow {
    display: none;
  }
}

.tooltip-button-wrap {
  .text-tooltip {
    float: left;
    padding-right: 8px;
  }
}

.tooltip {
  font-size: 9px;
}

.tooltip-disabled {
  display: none;
}

.loader {
  @include theme-aware("background-color", "loader");
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1150;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $primary-theme-color;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
}

.search-loader-text {
  padding-bottom: 20px;
  font-size: 16px;
}

// Dropdown

.dropdown-menu {
  @include theme-aware("background-color", "filter-background");
  @include theme-aware("color", "title");
  font-size: $font-size-default;
  border: 0;
}

.dropdown-item {
  color: $color-red;
  padding: 0.65rem 1rem;
}

.dropdown-toggle {
  font-size: $font-size-default;
}

.gear-icon {
  background: url(./img/gear-icon.svg) no-repeat 0 0;
  width: 25px;
  height: 23px;
}

// Lists

.icon-list {
  li {
    border: 0;
    padding: 0 0 0 32px;
    position: relative;
    margin-bottom: 10px;

    &.icon {
      &:after {
        background-repeat: no-repeat;
        content: "";
        position: absolute;
        left: 0;
        top: 4px;
        width: 22px;
        height: 14px;
        background-size: 21px 12px;
      }
    }

    .text-wrap {
      overflow: hidden;
      white-space: nowrap !important;
      text-overflow: ellipsis;
    }
  }
}

.icon-travel:after {
  background-image: url(./img/icon-travel.svg);
}

.icon-gaming:after {
  background-image: url(./img/icon-gaming.svg);
}

.icon-electronics:after {
  background-image: url(./img/icon-electronics.svg);
}

.icon-camera:after {
  background-image: url(./img/icon-camera.svg);
}

.icon-television:after {
  background-image: url(./img/icon-television.svg);
}

.badge {
  font-size: 90%;
}

.badge-light {
  @include theme-aware("color", "badge-info-color");
  @include theme-aware("background-color", "hover");
}

// Download button

.download-icon-button {
  font-size: 12px;
  position: relative;
  background-color: transparent;
  border: 0px;
  color: #1f87ee;
  padding: 0px 0px 0px 18px;

  &:before {
    content: "";
    position: absolute;
    background: url(./img/download-icon.svg) no-repeat 0 0;
    width: 15px;
    height: 15px;
    background-size: 12px;
    left: 0;
    top: 0px;
  }
}

.download-icon-button {
  &:hover,
  &:focus,
  &:active {
    background-color: transparent;
    color: $primary-theme-color;
    box-shadow: none;
    -webkit-box-shadow: none;
  }
}

// change this into scss for

// Progress bar

.progress-bar {
  background: $highchart-theme-blue;
}

// Header
// =====================================
header {
  // max-width: $container-width;
  margin: 0 auto;
  padding-bottom: 0;
  padding-top: 0;
  align-items: center;
  padding: 0px 15px;
}

span.user-name {
  margin-right: 8px;
}

span.user-name + img {
  width: 32px;
  height: 32px;
  border-radius: 100%;
}

.log-dropdown::after {
  background-size: 10px !important;
  margin-left: 10px !important;
}

.navbar-brand {
  cursor: pointer;
}

.navbar-wrap {
  padding-top: 4px;
  min-height: 60px;

  .navbar-brand {
    padding-top: 0;
  }

  .navbar-light {
    padding: 0px;

    .navbar-nav .nav-link {
      @include theme-aware("color", "title");
      font-weight: 400;

      &:hover {
        color: $primary-theme-color;
      }
    }
  }
}

//  .highcharts-container {
//     width:100% !important;
//     height:100% !important;
//  }
.navbar-light .navbar-toggler {
  color: #000;
  border: 0;
  padding: 0;
  outline: 0 !important;
}

.btn.btn-sign-in {
  padding: 0 20px;
  height: 30px;
  margin-top: 15px;
  font-size: 14px;
  white-space: nowrap;
}

// Headings

h1,
h3,
h4,
h5 {
  font-family: $font-grotesk-bold;
}

h1 {
  font-size: $h1-font-size;
}

h2 {
  font-size: $h2-font-size;
}

h3 {
  font-size: $h2-font-size;
}

h4 {
  font-size: $font-size-default;
}

.page-title {
  font-size: 52px;
  margin-top: 8px;
}

.filter-title {
  @include theme-aware("color", "title");
  font-size: 21px;
}

.heading-with-icon {
  background: url(./img/icon-qus.svg) no-repeat 0 0;
  padding-left: 30px;
  color: $color-light-grey;
  min-height: 22px;
  line-height: 22px;
}

.heading-without-icon {
  color: $color-light-grey;
  min-height: 22px;
  line-height: 22px;
}

.tooltip-icon-for-heading {
  background: url(./img/icon-qus.svg) no-repeat 0 0;
  min-width: 22px;
  min-height: 22px;
  display: inline-block;
  background-size: 22px;
  position: relative;
  top: 7px;
  padding-left: 30px;
}

// Globaly usable classses
.text-grey {
  color: $color-light-grey;
}

.text-medium {
  font-family: $font-grotesk-medium;
}

.text-bold {
  font-family: $font-grotesk-bold;
}

.text-truncated-inline {
  // display: inline-block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-truncated-multiline {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.tensorGif {
  width: 100px;
  height: auto;

  &.loading {
    display: none;
  }
}

// Modal custom style

.modal-header {
  font-family: $font-grotesk-bold;
  padding-bottom: 20px;
  border: none;

  .modal-title {
    @include theme-aware("color", "title");
    font-size: 18px;

    .tooltip-icon {
      margin-top: 4px;
    }

    .export-icon {
      background-color: $primary-theme-color;
      padding: 10px;
      color: white;
      border-radius: 0.25rem;
    }
  }
}

.modal-header .close {
  @include theme-aware("color", "title");
  outline: none;
}

// main site content
.main-site-content {
  padding-top: 30px;
  padding-bottom: 20px;

  &.dashboard {
    margin-top: 40px;
  }

  &.discovery {
    margin-top: 40px;
  }

  &.report-splash {
    margin-top: 40px;
  }

  .global-search {
    margin-bottom: 50px;
  }

  .reports {
    margin-top: 7.5%;
  }

  .back-button {
    padding: 6px 20px;
  }
}

.btn-export-data {
  + .dropdown-menu .dropdown-item {
    a {
      font-size: $font-size-small;
      color: #000;
      text-decoration: none;
    }

    &:hover {
      a {
        color: #fff;
      }
    }
  }
}

// Content
// ==================================

.search-and-topic-tensor {
  margin-top: 17px;
  margin-bottom: 17px;
}

.css-26l3qy-menu {
  @include theme-aware("background-color", "dropdown-background");
  @include theme-aware("color", "icon-text");
  @include theme-aware("box-shadow", "dropdown-shadow");
  border: none;

  .css-1n7v3ny-option {
    @include theme-aware("background-color", "dropdown-hover");
    @include theme-aware("color", "title");
  }

  .css-1gegc2z-option {
    @include theme-aware("background-color", "dropdown-background");
    @include theme-aware("color", "icon-text");
  }

  .css-1gegc2z-option:hover {
    @include theme-aware("background-color", "dropdown-hover");
    @include theme-aware("color", "title");
  }

  .css-18aukwf-option {
    @include theme-aware("background-color", "dropdown-hover");
    @include theme-aware("color", "title");
  }
}

.css-1pahdxg-control {
  @include theme-aware("background-color", "dropdown-background");
  border: 2px $primary-theme-color solid !important;
  border-radius: 12px !important;
  box-shadow: none !important;

  .css-1uccc91-singleValue {
    @include theme-aware("color", "icon-text");
  }

  .css-b8ldur-Input,
  .css-1g6gooi {
    @include theme-aware("color", "title");
  }
}

.campaign-manager,
.campaign-modal {
  input.form-control {
    @include theme-aware("background-color", "dropdown-background");
    @include theme-aware("box-shadow", "shadow");
    @include theme-aware("border-color", "dropdown-background");
    @include theme-aware("color", "title");
    border: solid 2px;
    border-radius: 12px;

    &:focus {
      @include theme-aware("background-color", "table-background");
      border: 2px $primary-theme-color solid;
      border-radius: 12px;

      @include box-shadow(none);
    }
  }
}

.search-wrap {
  margin-bottom: 20px;

  .input-group-text {
    background: url(./img/icon-search.svg) no-repeat 15px 16px #fff;
  }

  .form-control {
    height: calc(1.5em + 0.75rem + 12px);
    background: url(./img/icon-search.svg) no-repeat 15px 16px #fff;
    padding-left: 45px;
  }

  .dropdown {
    input {
      @include theme-aware("background-color", "table-background");
      @include theme-aware("box-shadow", "shadow");
    }
  }

  .discovery-search {
    width: 100%;
    margin-top: 5px;

    input {
      @include theme-aware("background-color", "dropdown-background");
      @include theme-aware("box-shadow", "shadow");
      @include theme-aware("border-color", "dropdown-background");
      @include theme-aware("color", "title");
      border: solid 2px;
      border-radius: 12px;

      &:focus {
        @include theme-aware("background-color", "table-background");
        border: 2px $primary-theme-color solid;
        border-radius: 12px;

        @include box-shadow(none);
      }
    }

    .dropdown-menu {
      @include theme-aware("background-color", "table-background");
      @include theme-aware("color", "title");
      border: none;

      .dropdown-title {
        font-size: 16px;
        padding: 0.75rem 1.5rem 0rem;
      }

      .dropdown-item {
        @include theme-aware("background-color", "table-background");
        padding: 0.65rem 1.5rem;
        font-size: 12px;
        color: $color-light-grey;

        .account-image {
          margin: 0 10px;
        }

        .dropdown-item-label {
          margin: 4px 0;
          width: 150px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &:hover {
          @include theme-aware("background", "dropdown-hover");
        }

        &:disabled {
          @include theme-aware("color", "dropdown-disabled");
        }
      }
    }

    .css-b8ldur-Input {
      @include theme-aware("color", "title");
    }

    .css-1pahdxg-control {
      @include theme-aware("background-color", "table-background");
      border: 1px $primary-theme-color solid;
      border-radius: 12px;

      @include box-shadow(none);
    }

    .css-26l3qy-menu {
      @include theme-aware("background-color", "table-background");
      @include theme-aware("color", "title");
      @include theme-aware("box-shadow", "shadow-dark");
      border: none;

      .css-1n7v3ny-option {
        @include theme-aware("background-color", "dropdown-hover");
      }
    }
  }

  .input-group-btn {
    padding-left: 10px;

    .btn {
      width: 160px;
      height: 48px;
    }
  }
}

.row.range-row {
  padding-top: 12px;
  padding-bottom: 4px;
}

.slidecontainer {
  padding-top: 5px;

  .rc-slider-rail {
    background: $color-grey;
  }

  .rc-slider-track {
    background: $primary-theme-color;

    @include box-shadow($primary-button-shadow);
  }

  .rc-slider-handle {
    @include box-shadow($primary-button-shadow);

    background: $primary-theme-color;
    border: 0;
    margin-left: -6px;
    margin-top: -4px;
    width: 12px;
    height: 12px;
  }

  .slidecontainer {
    display: inline-block;
  }
}

.slidecontainer {
  &.dots-wrap {
    text-align: center;

    span.dot {
      @include border-radius(50%);

      display: inline-block;
      width: 15px;
      height: 15px;
      background: $primary-theme-color;
      margin: 0px 8px;

      &.primary-light {
        background: lighten($primary-theme-color, 25%);
      }

      &.primary-dark {
        background-color: darken($primary-theme-color, 15%);
      }
    }
  }

  &.letters-wrap {
    text-align: center;

    span.letter {
      font-family: $font-grotesk-bold;
      margin: 0 10px;

      &.small {
        font-size: 10px;
      }

      &.medium {
        font-size: 16px;
      }

      &.large {
        font-size: 22px;
      }
    }
  }
}

// topic tensor
.search-and-topic-tensor {
  #topic-tensor {
    @include theme-aware("background", "table-background");
    @include theme-aware("box-shadow", "shadow");

    @include border-radius($blocks-radius);

    padding: $blocks-inside-padding;
  }
}

.range-slider {
  display: inline-block;
}

.tags {
  img {
    max-width: 100%;
  }
}

.range-row {
  font-size: 14px;

  // Range Slider
  .range-slider-wrap {
    @include theme-aware("color", "title");
  }
}

.ts-tag-clouds {
  height: 200px;
}

// Search Results Table
// ====================================
// Filters
.report-page-filters {
  .btn-default {
    min-width: 100px !important;
  }
}

// .block-records {
//   pointer-events: none;
// }

.filters-wrap {
  padding-bottom: 10px;

  .btn-dropdown-white {
    @include theme-aware("background", "dropdown-background");
    @include theme-aware("color", "title");
    border: 0;

    &:hover,
    &:focus,
    &:active,
    &.active {
      background: $primary-theme-color !important;
      color: #fff !important;

      @include box-shadow($primary-button-shadow !important);
    }
  }

  .btn-default {
    margin-bottom: 5px;
    margin-top: 5px;
    min-width: $min-filter-button-width;
    padding-right: 11px;
    padding-left: 11px;
    height: 41px;
    text-align: left;

    &:after {
      border: 0;
      @include theme-aware("background", "caret-icon");
      width: 15px;
      height: 8px;
      vertical-align: middle;
      margin-left: 3px;
      margin-top: 8px;
      background-size: 15px 5px;
      float: right;
    }

    &.settings.btn-dropdown-white {
      min-width: 41px;
      height: 41px;
      margin-right: 0;
      @include theme-aware("background", "settings-button");

      @include border-radius($blocks-radius !important);
    }

    &:hover,
    &:focus,
    &:active,
    &.active {
      &:after {
        background: url(./img/caret-down-white.svg) no-repeat 0 0;
        background-size: 15px 5px;
      }
    }
  }
}

.filters-wrap {
  .dot-filter-width {
    width: 41px !important;
  }
}

.tensor-list-dropdown > div > div:nth-child(1) {
  height: 45px;
}

.tensor-list-dropdown > div > span + div {
  height: 45px;
}

.tensor-list-dropdown > div > div > div:nth-child(2) {
  margin-left: 95px;
}

.tensor-list {
  h2 {
    font-size: 60px;
    margin-top: 40px;
  }

  .description {
    display: inline-block;
    width: 640px;
    margin-top: 20px;
  }

  .search {
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
  }

  .emails-count-input {
    padding-left: 10px;
    background: none;
    max-width: 250px;
    margin-left: 10px !important;
  }

  .align-items-center {
    flex-direction: column;
  }

  .left-align {
    float: left;
  }

  .tab-pane {
    margin-top: 80px;
  }

  .action-button {
    color: #fff;
    border: 0;
    height: 45px;
    width: 140px;
    box-shadow: 0px 3px 10px rgba(0, 194, 230, 0.32) !important;
  }

  .tensor-list-dropdown {
    flex: 0 0 15%;
    max-width: 25%;
  }

  .tensor-list-dropdown > div {
    width: 100%;
  }

  .tensor-list-dropdown {
    margin-right: 12px;
    margin-top: 1px;
    margin-bottom: 0 !important;
  }

  .spinner {
    border-color: $primary-theme-color;
    border-right-color: transparent;
    width: 30px;
    height: 30px;
  }

  .status-button-download {
    background: #00c2e6;
  }

  .status-button-failed {
    background: red;
    box-shadow: 0px 3px 10px rgba(255, 0, 0, 0.32) !important;

    &:active {
      color: red !important;
    }
  }

  .status-button-pending {
    background: #9f9f9f !important;
    color: white !important;
    box-shadow: 0px 3px 10px rgba(159, 159, 159, 0.32) !important;
  }

  .flex-column-items {
    flex-direction: column;
    margin-top: 15px;
  }
}

.custom-dropdown-column {
  padding: 1.2rem 1rem;

  &.width-lg {
    width: 630px;

    @media only screen and (max-width: 767px) {
      max-width: 630px;
      width: 100%;
    }
  }

  &.width-lg-1 {
    width: 410px;

    @media only screen and (max-width: 767px) {
      max-width: 410px;
      width: 100%;
    }
  }

  &.width-md {
    width: 202px;
  }

  &.width-md-2 {
    width: 305px;
  }

  &.width-md-3 {
    width: 225px;
  }

  .picky__input,
  select {
    font-size: $font-size-small;
    height: 30px;
    border-color: $input-border-color;
    outline: none;

    @include border-radius($blocks-radius);

    &:after {
      border: 0;
      background: url(./img/icon-search.svg) no-repeat right 0;
      width: 13px;
      height: 15px;
      top: 7px;
      right: 8px;
      background-size: 10px 15px;
    }

    &:focus {
      border-color: $primary-theme-color;
    }
  }

  .picky__dropdown {
    @include box-shadow($blocks-shadow-dark);
    @include border-radius($blocks-radius);

    top: 30px;
    color: $color-xlight-grey;
    font-size: $font-size-small;
  }

  .picky__filter__input {
    border-color: $input-border-color;
    color: $color-xlight-grey;
  }

  .col {
    display: inline-block;
  }

  .selected-tags-bottom {
    padding-top: 27px;

    .form-control {
      font-size: $font-size-small;
    }

    .active-filters-wrap.col-md-6 {
      padding-right: 0;
    }
  }

  .head {
    font-size: $font-size-default;
    position: relative;

    &.icon-audience,
    &.icon-influencer {
      padding-left: 20px;
      padding-right: 5px;

      &:after {
        content: "";
        width: 15px;
        height: 13px;
        display: inline-block;
        left: 0;
        top: 0;
        position: absolute;
      }
    }

    &.icon-audience:after {
      @include theme-aware("background", "audience-icon");
      color: $primary-theme-color;
    }

    &.icon-influencer:after {
      @include theme-aware("background", "influencer-icon");
      background-size: 25px 25px;
    }
  }
}

// active filters
.active-filters {
  ul {
    margin-bottom: 5px;
  }

  li {
    cursor: pointer;
    position: relative;
    padding-right: 30px;
    @include theme-aware("background", "hover");

    display: block;
    min-height: 35px;
    margin-right: 10px;
    margin-bottom: 5px;

    @include border-radius($blocks-radius);

    padding: 5px 22px 5px 28px;
    width: 100%;
    max-width: 205px;

    &.active-icon-audience,
    &.active-icon-influencer {
      &:after {
        content: "";
        width: 25px;
        height: 15px;
        display: inline-block;
        left: 10px;
        top: 27%;
        position: absolute;
      }
    }

    &.active-icon-audience:after {
      @include theme-aware("background", "audience-icon");
    }

    &.active-icon-influencer:after {
      @include theme-aware("background", "influencer-icon");
      background-size: 25px 15px;
    }

    .close {
      opacity: 1;
      text-shadow: none;
      position: absolute;
      top: 2px;
      right: 8px;
      font-family: $font-grotesk-Regular;
    }

    span {
      text-overflow: ellipsis;
      @include theme-aware("color", "badge-info-color");
      font-family: $font-grotesk-medium;
      line-height: 25px;
      vertical-align: middle;

      /* Required for text-overflow to do anything */
      white-space: nowrap;
      overflow: hidden;
    }

    .li-audience {
      @include theme-aware("background-color", "dropdown-background");
      @include theme-aware("border-color", "dropdown-hover");
      position: relative;
      margin-right: 0;
      border-radius: 12px;
      border: solid 2px;
      width: 90%;

      p {
        margin-left: 10px;
      }

      span {
        margin-right: 10px;
      }
    }

    .li-audience + .form-group {
      position: absolute;
      top: 0;
      right: 0;
      width: 100px;
      margin: 0;
    }

    .li-influencer {
      @include theme-aware("background-color", "dropdown-background");
      @include theme-aware("border-color", "dropdown-hover");
      position: relative;
      margin-right: 0;
      border-radius: 12px;
      border: solid 2px;
      width: 100%;
    }

    .li-weight {
      margin: 0;
    }
  }

  &.inside {
    li {
      cursor: text;
      margin-right: 5px;
      padding: 5px;
      padding-right: 15px;
      font-size: $font-size-small;
      margin-bottom: 5px;

      .close {
        right: 5px;
        font-size: 16px;
      }
    }
  }

  #no_filter {
    border-radius: 10px;
    background-color: $color-grey;
    @include theme-aware("background-color", "hover");
    opacity: 0.8;
  }
}

.left-audience {
  .active-filters-wrap {
    .active-filters.inside {
      li {
        margin-right: 0;
        padding: 0 90px 0 0;
        background-color: transparent;

        .p-0 {
          padding: 0 !important;
        }
      }
    }
  }
}

.gender-list {
  margin-top: 5px;
  margin-bottom: 0;

  li {
    margin-bottom: 6px;

    &:last-child {
      margin-bottom: 0;
    }

    .form-check {
      padding-left: 0;
    }
  }
}

.settings-list {
  margin-bottom: 0;
  padding: 0.25rem 0;

  li {
    padding: 0.35rem 0;

    /*padding:.35rem 1rem;*/
    .form-check {
      padding-left: 0;
      font-size: $font-size-small;
    }
  }
}

.other-filters {
  li {
    padding: 0.35rem 1rem;
  }
}

// custom stype for react-select-component
.multi-select-placeholder {
  color: hsl(0, 0%, 50%);
  margin-left: 2px;
  margin-right: 2px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  box-sizing: border-box;
}

.css-1rhbuit-multiValue {
  background-color: white !important;
  border-radius: 0px !important;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  margin: 0px !important;
  min-width: 0;
  box-sizing: border-box;
  display: none !important;
}

.css-1okebmr-indicatorSeparator {
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  background-color: hsl(0, 0%, 80%);
  margin-bottom: 8px;
  margin-top: 8px;
  width: 0px !important;
  box-sizing: border-box;
}

// Custom radio button style

.radio-container {
  display: block;
  position: relative;
  padding-left: 20px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

// Hide the browser's default radio button
.radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  @include theme-aware("background-color", "dropdown-hover");
  position: absolute;
  top: 4px;
  left: 0;
  height: 12px;
  width: 12px;
  border-radius: 50%;
}

// When the radio button is checked, add a blue background
.radio-container input:checked ~ .checkmark {
  background-color: $primary-theme-color;
}

// Create the indicator (the dot/circle - hidden when not checked)
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

// Show the indicator (dot/circle) when checked
.radio-container input:checked ~ .checkmark:after {
  display: block;
}

// Style the indicator (dot/circle)
.radio-container .checkmark:after {
  top: 4px;
  left: 4px;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: white;
}

.styled-textfield {
  padding: 15px 10px;
}

// Custom checkbox style

.styled-checkbox {
  position: absolute; // take it out of document flow
  opacity: 0; // hide it
  margin-left: 0;

  & + label {
    position: relative;
    cursor: pointer;
    padding: 0;
    padding-left: 24px;
    display: flex;
    align-items: center;
  }

  // Box.
  & + label:before {
    @include theme-aware("border-color", "dropdown-hover");
    border: 1.5px solid;
    content: "";
    margin-right: 10px;
    display: inline-block;
    width: 16px;
    height: 16px;
    position: absolute;
    left: 0;
    top: 2.5px;

    @include border-radius(0.25rem);
  }

  &.global-checkbox + label:before {
    left: 16px;
  }

  &.global-checkbox:checked + label:after {
    left: 19px;
  }

  // Box checked
  &:checked + label:before {
    background: $primary-theme-color;
    border: none;
  }

  // Disabled state label.
  &:disabled + label {
    color: #b8b8b8;
    cursor: auto;
  }

  // Disabled box.
  &:disabled + label:before {
    box-shadow: none;
    background: #ddd;
  }

  // Checkmark. Could be replaced with an image
  &:checked + label:after {
    content: "";
    position: absolute;
    left: 3px;
    top: 10px;
    background: white;
    width: 2px;
    height: 2px;
    box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white,
      4px -6px 0 white, 4px -8px 0 white;
    transform: rotate(45deg);
  }
}

// Edit and sort buttons
.edit-and-sort-buttons {
  margin-bottom: 11px;
}

.gear-edit-columns {
  cursor: pointer;
  color: $primary-theme-color;

  .btn-default.gear {
    width: 41px;
    height: 41px;
    padding: 9px;

    &:hover,
    &:focus,
    &:active {
      @include box-shadow($primary-button-shadow !important);
    }
  }

  .edit-col-list {
    margin-bottom: 0;
    padding: 0.5rem 0;

    li {
      padding: 0.35rem 1rem;

      .form-check {
        padding-left: 0;
      }
    }
  }
}

.sortby-button {
  margin-top: 3px;

  &.dropdown-toggle::after {
    border: 0;
    @include theme-aware("background", "caret-icon");
    width: 20px;
    height: 8px;
    vertical-align: middle;
    margin-left: 15px;
  }
}

.change-plan-dropdown {
  margin-top: 3px;

  &.dropdown-toggle::after {
    border: 0;
    background: url(./img/caret-down.svg) no-repeat 0 0;
    width: 20px;
    height: 8px;
    vertical-align: middle;
    margin-left: 15px;
    z-index: 999999;
  }
}

.ddmenu-col {
  &.ddmenu-btn {
    border-radius: 0.25rem;
  }

  // @include theme-aware("box-shadow", "shadow-dark");
  box-shadow: $dropdown-shadow;
  @include border-radius($blocks-radius);

  .form-field-col {
    margin-bottom: 0;

    input {
      @include theme-aware("color", "title");
      @include theme-aware("background-color", "dropdown-background");
      @include theme-aware("box-shadow", "shadow");
      @include theme-aware("border-color", "dropdown-hover");
      border: solid 2px;
      min-height: 38px;
      border-radius: 12px;
    }
  }
}

.mini-select {
  max-width: 70px;
  display: inline-block;
  width: 100%;

  select {
    font-size: 12px;
  }
}

.select-field-col {
  display: inline-block;
  max-width: 80px;
  width: 100%;
  margin-right: 10px;
  margin-bottom: 1rem;

  .css-tlfecz-indicatorContainer {
    padding: 0;
    padding-right: 5px;
  }

  &.full {
    max-width: 100%;
  }

  select {
    font-size: 12px;
  }

  &.last {
    margin-right: 0;
    margin-bottom: 0;
  }

  &.follower-col {
    margin-bottom: 0;
  }
}

// Table

.search-results-wrap {
  margin-bottom: 30px;

  .table-outer {
    @include border-radius($blocks-radius);
    @include theme-aware("box-shadow", "shadow");
    @include theme-aware("background", "table-background");

    font-size: 14px;
    padding: 15px;

    @media (max-width: $mobile-width) {
      padding: 0;
    }
  }

  .records-table {
    font-size: 12px;
    color: $color-light-grey;
  }

  .no-left-right-padding {
    padding: 5px 0px 0px 0px;
  }

  .table {
    margin-bottom: 0;
    font-family: $font-grotesk-Regular;

    &.table-records {
      th,
      td {
        padding-left: 25px;
        padding-right: 35px;
      }
    }

    thead th {
      vertical-align: middle;
    }

    tbody td {
      vertical-align: middle;
      padding-top: 8px;
      padding-bottom: 8px;

      color: #818181;

      .text-wrap {
        max-width: 50%;
      }

      .btn-outline-primary {
        // height: 34px;
        // line-height: 34px;
        // padding: 0 10px;
        // // width: 100%;
        // width: 110px;
        // max-width: 120px;
        max-height: 105px;
        line-height: 34px;
        padding: 0 10px;
        width: 100%;
      }
    }
  }

  .table-hover tbody tr:hover {
    background-color: $color-grey;
  }

  .table-hover tbody .selected {
    @include theme-aware("background-color", "color-background");
  }
}

.search-results-wrap {
  .media-left {
    margin-right: 10px;

    img {
      width: 50px;
      height: 50px;

      @include theme-aware("box-shadow", "image-shadow");
      @include theme-aware("border-color", "dropdown-background");
      @include border-radius(50%);
      border: 2px solid;
      background-color: $primary-theme-color-70;
    }
  }

  .username-col {
    .form-control-lg {
      font-size: 14px;
    }

    a {
      text-decoration: none;
    }

    h4.media-heading {
      font-size: 14px;
      margin-bottom: 0;
    }
    span {
      @include theme-aware("color", "title");
    }
  }

  .footer {
    font-family: $font-grotesk-bold;
    background-color: $primary-theme-color;
    color: $color-white;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 1.25rem;
    z-index: 5;

    transition: 1s cubic-bezier(0, 1, 0.5, 1);
    transform: translateY(100%);

    &.show {
      transform: translateY(0);
    }

    .content {
      font-size: 16px;
      position: relative;
      right: 10%;

      @media (max-width: $mobile-width) {
        font-size: inherit;
        right: 0;
      }
    }
  }
}

.icon-arrow-up,
.icon-arrow-down {
  width: 20px;
  height: 10px;
  display: inline-block;
}

.icon-arrow-up {
  background: url(./img/icon-arrow-up.svg) no-repeat center 0;
}

.icon-arrow-down {
  background: url(./img/icon-arrow-down.svg) no-repeat center 0;
}

.us-flag-icon {
  width: 32px;
  height: 18px;
  display: inline-block;
  background: url(./img/us-flag-icon.svg) no-repeat center 0;
  position: relative;
  bottom: -4px;
}

// Footer
// =====================================
footer {
  background: #fff;
  padding: 26px 0;

  .copyright {
    color: $color-xlight-grey;
    text-align: center;
    font-size: 12px;
    padding-top: 3px;
  }
}

// #page-container {
// 	position: relative;
// 	min-height: 100vh;
//   }

// #app {
//   // padding-bottom: 5rem; /* Footer height
// }

//   #footer {
// 	position: absolute;
// 	bottom: 0;
// 	width: 100%;
// 	height: 5rem; /* Footer height
//   }

.footer-menu-items {
  margin-right: 0px;
  text-align: center;

  ul {
    margin-bottom: 0;
  }

  li {
    margin-left: 10px;
    margin-right: 10px;

    a {
      color: #000;
    }
  }
}

// Auth pages
// =====================================
.auth-wrap {
  background-color: #fafbfd;
}

.center-screen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
}

.response-svg {
  width: 25%;
  height: auto;
  margin-bottom: 25px;
}

.filter-white {
  filter: brightness(0) saturate(100%) invert(92%) sepia(92%) saturate(0%)
    hue-rotate(242deg) brightness(106%) contrast(107%);
}

.paddingTop {
  background-position: center 70px;
}

.toast-icon {
  filter: brightness(0) invert(1);
}

.page-head {
  text-align: center;
  padding-bottom: 25px;

  .auth_logo {
    width: $top_logo;
    margin: 50px auto;
  }

  h1 {
    @include theme-aware("color", "title");
    font-size: 32px;
    margin-bottom: 15px;
  }

  .sub-text {
    color: $color-light-grey;
    max-width: 340px;
    margin: 0 auto;
  }
}

.maxWidth {
  max-width: 100% !important;
}

form span.required-bottom {
  position: absolute;
  left: 15px;
  color: $color-red;
  font-size: 12px;
  padding-top: 4px;
}

.user-form-wrap {
  max-width: 429px;
  margin: 0 auto;
  min-height: 450px;
}

.user-form-wrap {
  .form-group {
    position: relative;
    margin-bottom: 28px;
  }

  label {
    color: $color-light-grey;
    font-family: $font-grotesk-bold;
  }

  .form-control {
    @include theme-aware("box-shadow", "shadow-dark");
    @include theme-aware("color", "title");
    border: none;
    border-radius: 12px;

    font-size: $font-size-default;
    height: 48px;
    padding-left: 24px;
    padding-right: 24px;

    &.has-icon-right {
      padding-right: 50px;
    }

    &:focus {
      border: 1px solid $primary-theme-color;
    }

    &.error {
      border: 1px solid $color-red;
    }

    &.text-grey {
      color: $color-light-grey;
    }
  }

  span.required {
    position: absolute;
    right: 0;
    color: $color-red;
    font-size: 11px;
    padding-top: 4px;
  }

  .button-showpass {
    position: absolute;
    right: 24px;
    top: 12px;
    cursor: pointer;

    svg path {
      fill: #b1bdc9;
    }

    svg line {
      fill: #b1bdc9;
      stroke: #b1bdc9;
    }

    &:hover svg path {
      fill: $primary-theme-color;
    }

    &:hover svg line {
      fill: $primary-theme-color;
      stroke: $primary-theme-color;
    }
  }

  ::placeholder {
    color: $color-light-grey;
  }

  :-ms-input-placeholder {
    color: $color-xlight-grey;
  }

  ::-ms-input-placeholder {
    color: $color-xlight-grey;
  }
}

.user-form-wrap {
  .bottom {
    padding-top: 10px;

    .btn {
      width: 200px;
      height: 48px;
      margin-bottom: 25px;
    }
  }
}

.forgot-pass-link {
  padding-top: 25px;
  width: 100%;

  .text-left {
    display: inline;
  }

  .text-right {
    display: inline;
    float: right;
  }
}

// Influencer Search & Post Analysis
// =====================================

.badge {
  display: inline-block;
  padding: 6px 12px;
  font-weight: 600;
  font-family: $font-grotesk-bold;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
}

.badge-pill {
  padding: 6px 10px;
  border-radius: 8rem;
}

.badge-soft-success {
  @include theme-aware("color", "badge-success-color");
  @include theme-aware("background-color", "badge-success-bg");

  &.fs--2 {
    font-size: 0.69444rem;
  }
}

.badge-soft-warning {
  color: #836200;
  background-color: #fff2ca;

  &.fs--2 {
    font-size: 0.69444rem;
  }
}

.badge-soft-danger {
  @include theme-aware("color", "badge-danger-color");
  @include theme-aware("background-color", "badge-danger-bg");

  &.fs--2 {
    font-size: 0.69444rem;
  }

  &.coming-soon {
    position: relative;
    top: 35px;
    right: 75px;
  }
}

.badge-soft-neutral {
  @include theme-aware("color", "badge-neutral-color");
  @include theme-aware("background-color", "badge-neutral-bg");

  &.fs--2 {
    font-size: 0.69444rem;
  }

  &.connect {
    position: relative;
    top: 35px;
    right: 60px;
  }

  &.shared-tag {
    @include theme-aware("box-shadow", "image-shadow");
    border-radius: 50%;
    padding: 5px;
  }
}

.badge-soft-empty {
  &.fs--2 {
    font-size: 0.69444rem;
  }
}

.col.is-search-block {
  padding-top: 20px;
  max-width: 100%;

  h1 {
    display: inline;
  }

  h1 span {
    vertical-align: middle;
  }

  .sub-text {
    max-width: 680px;
    margin-bottom: 30px;
  }

  .form-control {
    @include theme-aware("box-shadow", "shadow");
    @include theme-aware("color", "icon-text");

    border: none;
    font-size: $font-size-default;
    height: 48px;

    &:focus {
      border: 1px solid $primary-theme-color;
    }

    &.error {
      border: 1px solid $color-red;
    }
  }

  ::placeholder {
    color: $color-xlight-grey;
  }

  :-ms-input-placeholder {
    color: $color-xlight-grey;
  }

  ::-ms-input-placeholder {
    color: $color-xlight-grey;
  }

  .form-check {
    .styled-checkbox + label {
      padding-left: 30px;
      line-height: 1.7;
    }

    .styled-checkbox + label:before {
      width: 20px;
      height: 20px;
    }

    .styled-checkbox:checked + label:after {
      left: 5px;
      top: 10px;
    }
  }
}

.form-check {
  &.creator-lists-check {
    padding-left: 0;
    bottom: 10px;
  }
}

.outer-header {
  @include theme-aware("background-color", "sidebar-background");
  @include theme-aware("box-shadow", "shadow");

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
  height: 100%;
  width: 300px;
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  overflow-x: hidden;
  padding-top: 20px;

  &.show {
    transition: width 1s cubic-bezier(0, 1, 0.5, 1);
  }

  &.collapsed {
    width: 7%;

    transition: width 1s cubic-bezier(0, 1, 0.5, 1);

    .header-container {
      display: flex;
      flex-flow: column nowrap;

      .header-inner-container {
        width: 50%;
      }

      .top_logo {
        transform: scale(0.65);
      }

      .profile-main {
        position: absolute;
        bottom: 10px;

        .profile-main-container {
          .profile-container {
            transition: 1s cubic-bezier(0, 1, 0.5, 1);
          }
        }
      }

      .link-container {
        .link {
          padding: 15px 5px;

          display: flex;
          justify-content: center;

          .icon-container {
            padding-left: 0;
          }
        }

        .sidebar-page {
          display: none;
        }
      }
    }
  }

  @media (max-width: $mobile-width) {
    height: initial;
    width: 100%;
    position: initial;
    padding-top: 0px;
    overflow: initial;
  }

  .header-container-mobile {
    @include theme-aware("box-shadow", "shadow-dark");
    position: fixed;
    width: 100vw;
    background-color: inherit;
    padding-top: env(safe-area-inset-top);

    .navbar-toggler {
      svg {
        color: $primary-theme-color;
        stroke-width: 2;
      }
    }

    .link-container {
      transition: all 1s cubic-bezier(0, 1, 0.5, 1);
      justify-content: space-between;
      height: 100vh;

      .link {
        padding: 15px;
        display: flex;
        text-decoration: none;
        font-size: 15px;
        color: #818181;
        margin-top: 10px;

        .icon-container {
          margin-top: -1px;
          padding-left: 5px;
        }

        .report-icon {
          margin-top: -2px;
        }

        span {
          display: inline-block;
          font-size: 20px;
          font-family: $font-grotesk-bold;

          &.sidebar-page {
            padding-left: 10px;
          }
        }

        &:hover {
          background: $primary-theme-color-transparent;
          border-radius: 6px;
          color: $primary-theme-color;

          .link-icon {
            path {
              color: $primary-theme-color;
            }
          }
        }

        &.disabled:hover {
          background: none;
          color: #818181;
          cursor: pointer;

          .link-icon {
            path {
              color: #818181;
            }
          }
        }
      }

      .active {
        background: $primary-theme-color-transparent;
        color: $primary-theme-color;

        .link-icon {
          path {
            color: $primary-theme-color;
          }
        }
      }

      .balance-items-container {
        @include theme-aware("background-color", "color-background");
        @include theme-aware("box-shadow", "shadow-dark");

        border-radius: 16px;
        width: 200px;
        padding: 15px;
        margin: auto;

        .balance-item {
          font-weight: 700;
          display: flex;
          justify-content: space-between;
          margin-top: 20px;

          &.first {
            margin-top: 0;
            justify-content: center;
          }
        }
      }

      .logout {
        margin-bottom: calc(env(safe-area-inset-bottom) + 90px);
        align-self: center;

        hr {
          @include theme-aware("background-color", "dropdown-disabled");
          margin: 0 0 20px 0;
          opacity: 0.25;
        }

        .btn {
          width: 80vw;
          border: none;
          font-size: 18px;
          font-family: $font-grotesk-bold;
          text-decoration: underline;
        }
      }
    }
  }

  .header-container {
    padding-top: 30px;
    height: 100%;

    align-items: center;

    .top_logo {
      margin: auto;
      width: $top_logo;
      display: block;

      transform: scale(1);
      transition: transform 1s cubic-bezier(0, 1, 0.5, 1);
    }

    .header-inner-container {
      width: 230px;
      margin: auto;
      margin-top: 0;
    }

    .link-container {
      padding-top: 15px;
      transition: all 1s cubic-bezier(0, 1, 0.5, 1);

      .link {
        padding: 15px 15px 15px 5px;
        display: flex;
        text-decoration: none;
        font-size: 15px;
        color: #818181;
        font-weight: 700;
        margin-top: 10px;

        .icon-container {
          margin-top: -1px;
          padding-left: 5px;
        }

        .report-icon {
          margin-top: -2px;
        }

        .free-report-icon {
          background: #30c62e;
          color: #fff;
          font-size: 12px;

          border: none;
          border-radius: 12px;
          padding: 3px 10px;
          margin-left: 15px;
        }

        span {
          display: inline-block;

          &.sidebar-page {
            padding-left: 10px;
          }
        }

        &:hover {
          background: $primary-theme-color-transparent;
          border-radius: 6px;
          color: $primary-theme-color;

          .link-icon {
            path {
              color: $primary-theme-color;
            }
          }
        }

        &.disabled:hover {
          background: none;
          color: #818181;
          cursor: pointer;

          .link-icon {
            path {
              color: #818181;
            }
          }
        }
      }

      .active {
        background: $primary-theme-color-transparent;
        color: $primary-theme-color;
        border-radius: 6px;

        .link-icon {
          path {
            color: $primary-theme-color;
          }
        }
      }
    }

    .profile-main {
      position: absolute;
      bottom: 10px;

      left: 50%;
      transform: translateX(-50%);

      .profile-main-container {
        height: 125px;

        &.trialing {
          height: 185px;
        }

        &.expanded {
          height: 65px;

          &.trialing {
            height: 150px;
          }
        }

        &.profile-dropdown-open {
          height: 215px;

          &.trialing {
            height: 295px;
          }

          &.subscribed {
            height: 355px;

            &.trialing {
              height: 445px;
            }
          }
        }
      }

      .phone-icon {
        @include theme-aware("background-color", "color-background");
        border: 2px solid $primary-theme-color;

        width: 45px;
        height: 45px;
        padding: 8px;
        border-radius: 50%;

        path {
          fill: $primary-theme-color;
        }
      }

      .settings-icon {
        @include theme-aware("background-color", "color-background");
        @include theme-aware("color", "icon-sidebar");

        width: 50px;
        height: 50px;
        padding: 10px;
        border-radius: 50%;
      }

      .subscribe {
        display: block;
        margin: auto;
        padding: 13px 35px 13px 35px;
        margin-bottom: 20px;
      }

      .profile-main-container {
        transition: all 0.3s cubic-bezier(0.4, 0, 1, 1);

        .profile-container {
          padding: 0px 10px 10px 10px;

          .profile-card {
            display: flex;
            cursor: pointer;

            .profile-left {
              img {
                width: 50px;
                height: 50px;
                border-radius: 50%;
              }
            }

            .profile-middle {
              padding-left: 20px;
              width: 100%;

              .user {
                font-size: 10px;
                padding-top: 5px;
              }

              .name {
                font-weight: 700;
              }
            }

            .profile-right {
              .arrow-container {
                padding-top: 10px;
                cursor: pointer;

                i {
                  position: absolute;
                }

                .fa-angle-down {
                  padding-top: 10px;
                }
              }
            }
          }
        }

        .profile-balance-container {
          overflow: hidden;

          // border-bottom: 1px solid #d0d0d0;
          transition: all 0.4s cubic-bezier(0.4, 0, 1, 1);
          transform-origin: left top;

          // transform: translate3d(0, 0, 0);
          // transform: scaleY(0);
          height: 0;

          .balance-items-container {
            @include theme-aware("background-color", "color-background");
            @include theme-aware("box-shadow", "shadow-dark");

            border-radius: 16px;
            width: 200px;
            padding: 15px;
            margin: auto;

            .balance-item {
              font-weight: 700;
              display: flex;
              justify-content: space-between;
              margin-top: 20px;

              &.first {
                margin-top: 0;
                justify-content: center;
              }
            }
          }
        }

        .open {
          overflow: hidden;
          transition: all 0.4s cubic-bezier(0.4, 0, 1, 1);
          height: auto;
        }

        .action-container {
          transition: all 0.5s ease-in;
          font-weight: 700;

          .action {
            cursor: pointer;
            display: flex;
            margin-top: 20px;
            justify-content: space-between;
            text-decoration: none;
            color: #818181;
          }

          .sign-out {
            font-family: $font-grotesk-bold;
            color: $danger-button-bg-color;
          }
        }
      }
    }
  }
}

.outer-header-hidden {
  display: none;
}

button:focus span,
button:focus {
  text-decoration: none !important;
}

.width-30 {
  width: 30px;
}

//for removing arrows of numericfield
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.text-search-icon {
  background: url(/static/media/icon-search.4f1e807b.svg) no-repeat 95% 12.5px
    #fff;
  padding-right: 25px;
}

.text-search-icon-small {
  background: url(/static/media/icon-search.4f1e807b.svg) no-repeat 96.5% 8.5px
    #fff;
  padding-right: 25px;
}

.table-filter-input {
  input {
    border-radius: 0.25rem;
    padding: 1rem;
  }
}

.table-grey-background {
  background-color: $color-grey;
}

.table-grey-row {
  width: 100%;
  height: 85px;
}

.btn-text-row {
  display: flex;
  width: 100%;
  align-items: center;
  position: relative;
}

.records-remaining {
  font-size: 16px;
  font-family: $font-grotesk-medium;
  letter-spacing: 0.44px;
  min-width: 18%;
  position: absolute;
  left: 0;
  white-space: nowrap;
}

.unlock-btn {
  flex: 0 0 100%;
  text-align: center;
}

.unlock-btn button {
  width: 175px;
  height: 50px;
}

.unlock-more-modal-heading {
  height: 31px;
  color: #000000;
  font-size: 28px;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 27px;
}

.unlock-more-modal-subheading {
  height: 19px;
  color: #000000;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.44px;

  span {
    /* Text style for "12,546" */

    font-weight: 400;
    font-family: $font-grotesk-Regular;
  }
}

.no-min-height {
  min-height: auto;
}

.modal-header .modal-title {
  width: 100%;
}

.unlock-button {
  width: 175px;
  height: 50px;
  border-radius: 4px;
}

.posiion-relative {
  position: relative;
}

.blur-bg {
  filter: blur(5px);
}

.social-cloud-loader {
  z-index: 1150;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $primary-theme-color;
}

#h-captcha {
  text-align: center !important;
}

//
.copy-filters-arrow {
  z-index: 30;
  position: absolute;
  top: 28px;
  left: 305px;
  color: hsl(0, 0%, 50%);
  font-size: 20px;
  cursor: pointer;
}

.response-svg {
  width: 25%;
  height: auto;
}

//pagination component primary color chanage
.page-item.active .page-link {
  background-color: $primary-theme-color;
  border-color: $primary-theme-color;
}

.page-link {
  @include box-shadow($blocks-shadow);
  @include theme-aware("background-color", "table-background");
  color: $primary-theme-color;
  border-radius: 0.25rem;
  border: none;
  margin: 0 5px;
  text-align: center;
}

.page-link:hover {
  color: $primary-theme-color;
}

.page-item.disabled .page-link {
  @include theme-aware("background-color", "table-background");
  color: $grey-disabled;
}

.css-2b097c-container {
  position: relative;
  box-sizing: border-box;
  max-width: 284px;
  margin-top: 5px;

  @media only screen and (max-width: 767px) {
    max-width: 100% !important;
  }

  .css-yk16xz-control {
    @include theme-aware("background-color", "dropdown-background");
    @include theme-aware("box-shadow", "shadow");
    @include theme-aware("border-color", "dropdown-hover");
    border: solid 2px;
    border-radius: 12px;

    .css-1uccc91-singleValue {
      @include theme-aware("color", "icon-text");
    }
  }
}

.logo_pic {
  width: $logo_size;
  height: $logo_size;
}

// Media Queries
// =====================================

// Mobile
@media only screen and (max-width: 992px) {
  .auth-wrap {
    background-size: 100%;
  }
}

@media only screen and (max-width: 480px) {
  .copy-filters-arrow {
    top: 60px;
    left: 126px;
  }
}

@media (min-width: 481px) {
  .copy-filters-arrow {
    left: 306px;
    top: 28px;
  }
}

// @media only screen and (max-width:768px){
// 	.search-and-topic-tensor .search-block {
// 		margin-bottom: 20px
// 	}

// }
@media only screen and (max-width: 767px) {
  .edit-and-sort-buttons {
    float: right;
    width: 100%;

    .btn.btn-link {
      padding-left: 0;
    }
  }

  .btn.btn-sign-in {
    margin-bottom: 20px;
    margin-top: 5px;
  }

  .col.is-search-block {
    padding-top: 20px;
    padding-bottom: 20px;

    .input-checkbox-wrap,
    .form-control {
      width: auto;
    }

    .input-group-btn {
      padding-left: 0;
      padding-top: 15px;
      margin-top: 20px;

      .btn {
        margin-left: 0;
        margin-right: 10px;
        width: 135px;
      }
    }
  }

  .unlock-btn {
    margin-left: 50px;
  }
}

@media only screen and (max-width: 479px) {
  .search-wrap .input-group-btn .btn {
    width: 78px;
  }

  .slidecontainer {
    &.dots-wrap span.dot {
      margin: 0px 6px;
    }

    &.letters-wrap span.letter {
      margin: 0px 5px;
    }
  }
}

// Desktop
@media only screen and (min-width: 768px) {
  .navbar-wrap {
    height: 70px;

    .navbar-brand {
      margin-left: 5%;
      margin-top: -4px;
    }
  }

  .navbar-expand-md .navbar-nav {
    margin: 0 auto;
    padding-top: 6px;

    a {
      cursor: pointer;
    }
  }

  .navbar-wrap .navbar-light .navbar-nav {
    .nav-link {
      position: relative;
      font-size: 14px;
      padding-bottom: 15px;
      line-height: 37px;

      &.active {
        &:after {
          content: "";
          background: $primary-theme-color;
          height: 2px;
          position: absolute;
          width: 100%;
          max-width: 80px;
          margin: 0 auto;
          left: 0;
          right: 0;
          bottom: 0;
        }
      }
    }
  }

  .navbar-wrap .navbar-light .nav-item {
    margin: 0 6px;
  }

  .ts-tag-clouds {
    height: 250px;
  }

  // Range Slider

  .range-slider-wrap {
    .slidecontainer {
      padding: 4px 10px 0px 10px;
    }

    span {
      font-family: $font-grotesk-medium;
      color: $color-light-grey;
    }
  }

  // Search Results Table

  .results-found {
    font-size: 21px;
    padding-bottom: 15px;
    padding-top: 11px;
  }

  .slidecontainer {
    &.letters-wrap {
      margin-top: -8px;
    }
  }

  // Influencer Search & Post Analysis
  // ====================================
  .col.is-search-block {
    .sub-text {
      margin-bottom: 40px;
    }

    .btn {
      margin-left: 4px;

      + .btn {
        margin-left: 12px;
      }
    }

    .input-checkbox-wrap {
      width: 100%;
      max-width: 344px;
    }
  }
}

@media only screen and (min-width: 992px) {
  .navbar-wrap .navbar-light .nav-item {
    margin: 0 24px;
  }

  .navbar-wrap .navbar-light .navbar-nav {
    .nav-link {
      font-size: 16px;
    }
  }

  .btn.btn-sign-in {
    width: 126px;
  }

  // .search-and-topic-tensor {
  // 	.search-block, .topic-tensor {
  // 	height: 305px;
  // 	}
  // }

  .ts-tag-clouds {
    height: 220px;
  }

  // Footer
  .footer-menu-items {
    li {
      margin-left: 48px;
      margin-right: 0;
    }
  }

  .range-slider-wrap {
    .col-lg-3 {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.gear-edit-columns {
  position: relative;
}

.gear-edit-columns .ddmenu-col.dropdown-menu.show {
  position: absolute;
  transform: none !important;
  top: 42px !important;
  left: auto !important;
  right: 0;
}

@media only screen and (min-width: 1199px) {
  // .search-and-topic-tensor {
  // 	.search-block, .topic-tensor {
  // 	height: 225px;
  // 	}
  // }
  .ts-tag-clouds {
    height: 200px;
  }

  body .container {
    max-width: $container-width;
  }
}

// @media only screen and (min-width:280px) {
//     .filters-wrap {
//         .btn-group {
//             .btn-default {
//                 margin-right: 0;
//             }
//         }
//     }
// }

@media (min-width: 576px) and (max-width: 767px) {
  .filters-wrap {
    .btn-group.second.col-md-4 {
      .custom-dropdown-column.width-lg {
        left: auto !important;
        right: 22px;
        -webkit-transform: translate3d(8px, 56px, 0px) !important;
        -moz-transform: translate3d(8px, 56px, 0px) !important;
        transform: translate3d(8px, 56px, 0px) !important;
      }

      .custom-dropdown-column.width-lg-1 {
        left: auto !important;
        right: 22px;
        -webkit-transform: translate3d(8px, 56px, 0px) !important;
        -moz-transform: translate3d(8px, 56px, 0px) !important;
        transform: translate3d(8px, 56px, 0px) !important;
      }

      .custom-dropdown-column.width-md {
        left: auto !important;
        right: 22px;
        -webkit-transform: translate3d(8px, 56px, 0px) !important;
        -moz-transform: translate3d(8px, 56px, 0px) !important;
        transform: translate3d(8px, 56px, 0px) !important;
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .filters-wrap {
    .btn-group.third.col-md-4 {
      .custom-dropdown-column.width-lg {
        left: auto !important;
        right: 22px;
        -webkit-transform: translate3d(8px, 56px, 0px) !important;
        -moz-transform: translate3d(8px, 56px, 0px) !important;
        transform: translate3d(8px, 56px, 0px) !important;
      }

      .custom-dropdown-column.width-lg-1 {
        left: auto !important;
        right: 22px;
        -webkit-transform: translate3d(8px, 56px, 0px) !important;
        -moz-transform: translate3d(8px, 56px, 0px) !important;
        transform: translate3d(8px, 56px, 0px) !important;
      }

      .custom-dropdown-column.width-md {
        left: auto !important;
        right: 22px;
        -webkit-transform: translate3d(8px, 56px, 0px) !important;
        -moz-transform: translate3d(8px, 56px, 0px) !important;
        transform: translate3d(8px, 56px, 0px) !important;
      }
    }
  }
}

@media (min-width: 992px) {
  .filters-wrap {
    .btn-group.sixth.col-md-4 {
      .custom-dropdown-column.width-lg {
        left: auto !important;
        right: 22px;
        -webkit-transform: translate3d(8px, 56px, 0px) !important;
        -moz-transform: translate3d(8px, 56px, 0px) !important;
        transform: translate3d(8px, 56px, 0px) !important;
      }

      .custom-dropdown-column.width-lg-1 {
        left: auto !important;
        right: 22px;
        -webkit-transform: translate3d(8px, 56px, 0px) !important;
        -moz-transform: translate3d(8px, 56px, 0px) !important;
        transform: translate3d(8px, 56px, 0px) !important;
      }

      .custom-dropdown-column.width-md {
        left: auto !important;
        right: 22px;
        -webkit-transform: translate3d(8px, 56px, 0px) !important;
        -moz-transform: translate3d(8px, 56px, 0px) !important;
        transform: translate3d(8px, 56px, 0px) !important;
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .filters-wrap {
    .btn-group {
      .custom-dropdown-column.width-lg {
        width: 280px;

        .row {
          .col-sm-6 {
            flex-basis: auto;
            flex-grow: initial;
            max-width: 100%;

            .left-audience {
              .active-filters-wrap {
                .active-filters.inside {
                  li {
                    display: block;
                  }
                }
              }
            }
          }
        }
      }

      .custom-dropdown-column.width-lg-1 {
        width: 280px;

        .row {
          .col-sm-6 {
            flex-basis: auto;
            flex-grow: initial;
            max-width: 100%;

            .left-audience {
              .active-filters-wrap {
                .active-filters.inside {
                  li {
                    display: block;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.sm-dropdown {
  .custom-dropdown-column.width-lg {
    width: auto !important;
  }

  .select-field-col {
    max-width: 45%;
  }

  .reach {
    .select-field-col {
      max-width: 100%;
    }
  }
}

.location-box .css-2b097c-container {
  min-width: 185px;
}

.inline-filters {
  .active-filters {
    li {
      display: inline-block;
      max-width: 200px;
    }
  }
}

.active-filters {
  .list-inline {
    li {
      @include theme-aware("background-color", "dropdown-hover");
      display: inline-block;
      width: 100%;
      padding: 0;
      border-radius: 12px;

      p {
        @include theme-aware("color", "icon-sidebar");
        height: 36px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        padding: 10px 15px 5px 5px;
        margin-bottom: 0;
        margin-left: 10px;
      }

      span {
        margin-right: 5px;
      }
    }
  }

  .list-inline.all-selected-filters {
    li {
      height: auto;
      width: auto;
      max-width: 1000px;
      padding: 4px 50px 5px 35px;
    }

    // please don't change it before discussion
  }
}

/* surender */

.update-card-btn {
  margin: 50px auto;
}

.allInfo {
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.PbInfo,
.cardInfo,
.balanceInfo {
  padding: 10px 20px;
  box-shadow: 0px 0px 10px rgba(76, 76, 76, 0.16);
  border-radius: 4px;

  h3 {
    font-size: 15px;

    span {
      font-size: 12px;
    }
  }
}

.balanceInfo {
  // width: 250px;
  width: 30%;

  .list-group-item {
    border: none;
    padding: 0.75rem 0;

    span {
      font-weight: 800;
    }

    i {
      float: right;
      color: #333;
    }
  }
}

.PbInfo {
  // width: 320px;
  width: 40%;

  .PlanName {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .btn {
      width: 130px;
    }
  }
}

.cardInfo {
  // width: 300px;
  width: 30%;
  margin: 0 30px;

  .card-container {
    padding: 0 0px;
    box-shadow: none;
    border-radius: 0;
  }
}

.apiInfo {
  word-wrap: break-word;
  margin-top: 50px;

  .list-group-item {
    border: none;
    padding: 0 0 15px;
  }

  .btn {
    width: 100%;
  }
}

.admin-table {
  .table-hover {
    tbody {
      .hidebottomshadow {
        box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.5);
        border-radius: 6px 6px 0 0 !important;

        &:hover {
          box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.5) !important;
          border-radius: 6px 6px 0 0 !important;
        }
      }

      .hidetopshadow {
        box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.5);
        border-radius: 0 0 6px 6px !important;
        border-top: 1px solid #fafafa;

        &:hover {
          box-shadow: 0px 3px 4px rgba(53, 26, 26, 0.5) !important;
          border-radius: 0 0 6px 6px !important;
        }
      }
    }
  }
}

.social-icon-img {
  &.instagram {
    background: radial-gradient(
      circle at 33% 100%,
      #fed373 4%,
      #f15245 30%,
      #d92e7f 62%,
      #9b36b7 85%,
      #515ecf
    );
  }

  &.youtube {
    background-color: #e02f2f;
  }

  &.tiktok {
    background-color: $color-black;
  }
}

.account-image {
  padding: 2px;

  img {
    @include theme-aware("box-shadow", "image-shadow");
    @include theme-aware("border-color", "dropdown-background");
    border: 2px solid;
    background-color: $primary-theme-color-70;

    svg {
      fill: $primary-theme-color-70;
    }
  }

  .social-icon-img {
    position: relative;
    top: 15px;
    right: 15px;

    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 2px solid;
  }

  .user-circle {
    color: #fff;
    background-color: $primary-theme-color-70;
  }
}

.demo {
  display: inline-flex;
  align-items: center;

  background: $primary-theme-color;
  opacity: 0.9;
  color: #fff;
  font-size: 12px;

  border: none;
  border-radius: 12px;
  padding: 3px 10px;
  margin-left: 10px;
}

.infulence-dropdown-list {
  width: 500px;
  margin: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

.infulence-dropdown-list-2 {
  padding: 0px 5px 0px 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .account-image {
    padding: 10px 0 10px 5px;
  }

  .highlight {
    color: $primary-theme-color;
    font-weight: bolder;
  }

  .generate-report-row {
    @include theme-aware("color", "title");
  }

  .report-analyze {
    @include theme-aware("box-shadow", "shadow-dark");
    position: absolute;
    opacity: 0;
    color: white;
    background-color: $primary-theme-color;
    border-radius: 0.25rem;
    padding: 10px 15px;
    margin-right: 30px;
    margin-top: 5px;
  }

  &:hover {
    @include theme-aware("background-color", "dropdown-hover");

    .report-analyze {
      opacity: 1;
      z-index: 100;
    }

    .follower-font {
      -webkit-filter: blur(20px);
      -moz-filter: blur(20px);
      -o-filter: blur(20px);
      -ms-filter: blur(20px);
      filter: blur(20px);
    }
  }

  @media (max-width: 767px) {
    .follower-font {
      opacity: 0;
    }

    .report-analyze {
      opacity: 1;
      font-size: 12px;
      margin-right: 10px;
    }
  }
}

@media (min-width: 576px) {
  .container {
    max-width: 960px;
  }
}

.filters-wrap {
  .tooltip-icon {
    top: 0;
  }
}

@media (max-width: 767px) {
  .copy-filters-arrow {
    left: 93%;
  }
  .search-Splash-text {
    width: -webkit-fill-available !important;
  }
  .search-field-button {
    width: -webkit-fill-available !important ;
  }
  .preview-accounts-dropdown {
    transform: translate3d(0px, 46px, 0px) !important;
  }
  .dropdown-list {
    width: auto;
  }
  .mobile-view-past-report {
    display: none;
  }
  // .fixed-search{
  //   width: 300px;
  // }
}

@media (max-width: 480px) {
  .copy-filters-arrow {
    left: 126px;
  }
}

.search-field-button {
  margin-left: 10px !important;
  width: 160px;
}

.btn {
  transition: inherit !important;
  -webkit-transition: inherit !important;
}

.mw-none {
  max-width: none !important;
}

.mr-left {
  margin-left: -4px;
}

.minwdth-auto {
  min-width: 250px;
}

.user-profile-table {
  .data-values {
    list-style: none;
    padding-left: 0;

    li {
      padding-top: 10px;
      padding-bottom: 10px;
    }

    .key {
      color: #8f8f8f;
      font-weight: 700;
    }
  }
  th {
    font-weight: 600;
    font-size: 11px;
    line-height: 14px;
    color: #788999;
  }

  .past-export-platform {
    img {
      @include theme-aware("box-shadow", "image-shadow");
      @include theme-aware("border-color", "dropdown-background");
      @include border-radius(50%);

      width: 45px;
      height: 45px;
      border: 2px solid;
    }

    .exports-used {
      @include theme-aware("background-color", "color-background");
      margin-left: 15px;
    }

    .example-demo {
      position: absolute;
      bottom: 35px;
      left: 9%;
    }
  }

  .past-export-filters {
    .badge {
      margin: 5px;
    }
  }
}

.center-dropdown {
  margin: 0 auto;
}

.beta-icon {
  width: 50px;
  height: 50px;
  padding: 0px 0px 5px 5px;
}

.university-beta-icon {
  width: 24px;
  padding-left: 0px;
  padding-right: 0px;
}

.padding-class {
  padding: 10px 15px;
  min-width: 250px;
}

.relevance-dropdown-parent {
  .css-2b097c-container {
    max-width: 100% !important;
    flex: 1 1 auto;
    width: 1%;
  }

  .css-1hwfws3 {
    padding-left: 30px;
  }

  .css-1g48xl4-IndicatorsContainer {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}

.error-nav {
  cursor: pointer;
}

.credits-message {
  color: $color-light-grey;
  font-size: 14px;
  font-weight: 400;
}

.input-select__icon {
  img {
    @include theme-aware("box-shadow", "image-shadow");
    @include theme-aware("border-color", "dropdown-background");
    width: 30px;
    margin: 2px;
    margin-right: 5px;
    border-radius: 50%;
    border: 2px solid;
  }
}

.insta-dropdown {
  .input-select__single-value {
    display: flex;
    align-items: center;
  }

  .heading-with-icon {
    background: none;
    padding-left: 0;
  }

  .css-yk16xz-control {
    @include theme-aware("background-color", "dropdown-background");
    @include theme-aware("color", "title");
    @include theme-aware("box-shadow", "shadow-dark");
    border: none;
  }

  .css-26l3qy-menu {
    @include theme-aware("background-color", "dropdown-background");
    @include theme-aware("color", "title");
    @include theme-aware("box-shadow", "shadow-dark");
    border: none;
    margin-top: -50px;
  }

  .css-26l3qy-menu:hover {
    @include theme-aware("background-color", "dropdown-background");
  }

  .css-1pahdxg-control {
    @include theme-aware("background-color", "table-background");
    @include theme-aware("color", "title");
    @include theme-aware("box-shadow", "shadow-dark");
    border: none !important;
  }

  .css-1hwfws3 {
    padding: 2px 0px 2px 7px;
  }
}

.react-select-single-select-with-icon-options {
  display: block;
  cursor: default;

  &:hover {
    @include theme-aware("background", "hover");
  }

  &:active {
    background: #b2d4ff;
  }
}

.react-select-single-select-with-icon-isSelected {
  @include theme-aware("background", "hover");
}

.min-height48 {
  .css-yk16xz-control {
    min-height: 48px;
  }

  .css-1pahdxg-control {
    min-height: 48px;
    border: none;
    box-shadow: none;
  }
}

.post-search {
  .btn.btn-primary {
    width: 50px;
  }

  .relevance-dropdown-parent {
    .active .css-1hwfws3 {
      padding-left: 45px;
    }
    .css-1hwfws3 {
      padding-left: 20px;
    }
  }

  .relevance-search-tag {
    font-family: $font-grotesk-bold;
    line-height: 1rem;
    position: absolute;
    top: 32%;
    left: 6%;
    z-index: 1;
    background-color: $primary-theme-color;
    color: $color-white;
    padding: 4px 8px;
    border-radius: 0.25rem;
  }
}

.manage-campaigns {
  .page-header {
    .btn {
      min-width: 160px;
      height: 48px;
    }
  }
}

.modal-header .modal-title {
  font-size: 18px;
  font-family: $font-grotesk-bold;
}

.modal-content {
  @include theme-aware("background-color", "modal-background");
  border: none;
  border-radius: 14px;
  padding: 15px;

  .export-waiting {
    font-size: 18px;
    color: #818181;
  }

  .export-button,
  .modal-button {
    padding: 10px 25px;
    border-radius: 0.25rem;
  }
}

.modal-footer {
  display: flex;
  align-items: center;
  border: none;
  justify-content: space-between;
  .form-check-label {
    @include theme-aware("color", "title");
    margin-bottom: 0;
    font-weight: bold;
    cursor: pointer;
  }
  .form-check {
    display: flex;
    align-items: center;
  }
}

.text-sub {
  @include theme-aware("color", "title");
  font-size: 18px;
  font-weight: 600;
  align-items: center;
  margin-bottom: 5px;
  span {
    font-size: 13px;
    font-style: italic;
    color: #a6a6a6;
  }
}
.styled-textfield.center-dropdown.form-control {
  @include theme-aware("background", "dropdown-background");
  @include theme-aware("box-shadow", "shadow-dark");
  @include theme-aware("color", "title");
  font-size: 19px;
  height: 40px;
  padding: 25px 15px;
  margin: 20px 0px 0px 0px;
  border: 2px solid $primary-theme-color-50;

  &:focus {
    border: 2px solid $primary-button-active-outline-color;
  }
}
.hub-spot.justify-content-around.d-flex {
  margin: 30px 0;
  margin-top: 60px;
}

.connect-account {
  margin: 15px 0;

  a {
    text-decoration: none;
    color: $primary-theme-color;
    font-weight: bold;
    font-size: 14px;
    padding-left: 15px;
  }
}

.template-list-columns {
  padding: 0;
}

.integrations {
  margin: 25px 0;

  .center-int {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .hun-1 {
    z-index: 100;
    font-size: 18px;
    font-weight: 600;
    width: 156px;
    border-radius: 0.25rem;
    padding: 15px 10px;
    @include theme-aware("border-color", "modal-background");
    border: solid 2px;
    &:hover {
      cursor: pointer;
    }
    &.active {
      border: 2px $primary-theme-color solid;
      cursor: pointer;
    }
  }
  .img-png {
    @include theme-aware("color", "title");
    margin: 15px auto;
    width: 156px;
    font-size: 18px;
    display: flex;
    align-items: center;
    span {
      margin-right: 10px;
    }
  }

  .img-png.disabled {
    @include theme-aware("background", "loader");
    opacity: 0.25;
    cursor: default;
  }
}

.img-msg {
  width: 156px;
  opacity: 0.5;
  font-size: 18px;
}
.list-btn-col {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.done-pop {
  padding-top: 30px;
}

.main-site-content .global-search {
  margin-bottom: 50px;
  position: fixed;

  max-width: 1118px;
  margin: 0 auto;
  z-index: 999;
  top: 2.5%;
  right: 20px;
}
.main-site-content .global-search.left {
  right: unset;
}
.main-site-content .page-title {
  @include theme-aware("color", "title");
  margin-top: 50px;
  font-weight: bold;
  font-size: 36px;
  line-height: 45px;
  letter-spacing: 0.9px;
}
.page-title-sub {
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.3px;

  color: #788999;
}
.image-overlay {
  height: auto;
  position: fixed;
  left: 0;
  right: 0;
}
.image-overlay:before {
  content: "";
  position: absolute;
  background: #000;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  z-index: 12;
  opacity: 0.75;
  height: 100vh;
}
.fixed-search .action-sorting {
  @include theme-aware("box-shadow", "shadow-dark");
  cursor: pointer;
  width: 50px;
  height: 50px;
  background: $primary-theme-color;
  opacity: 0.9;
  color: white;

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-left: auto;
}

#globalSearch {
  @include theme-aware("background-color", "color-background");
  width: 500px;
  border: none;

  &.search-radius {
    @include theme-aware("background-color", "color-background");
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  ::placeholder,
  :-ms-input-placeholder,
  ::-ms-input-placeholder {
    color: $color-grey;
  }
}
.fixed-search {
  //  padding: 18px;
  background: #fff;
  position: fixed;
  margin-top: 10px;
  // width: 600px;
  z-index: 999;
  // padding-top: 6px;
  top: 0;
}

.pagination {
  .page-item {
    .page-link {
      box-shadow: none;
    }
  }

  .page-item:nth-child(2),
  .page-item:nth-child(8) {
    .page-link {
      width: 50px;
      height: 33px;

      text-align: center;
    }
  }
}

.popover {
  @include theme-aware("background-color", "dropdown-background");

  button {
    @include theme-aware("color", "title");
  }
  &.bs-popover-top {
    .arrow::after {
      @include theme-aware("border-top-color", "dropdown-background");
    }
  }
  &.bs-popover-right {
    .arrow::after {
      @include theme-aware("border-right-color", "dropdown-background");
    }
  }
  &.bs-popover-bottom {
    .arrow::after {
      @include theme-aware("border-bottom-color", "dropdown-background");
    }
  }
  &.bs-popover-left {
    .arrow::after {
      @include theme-aware("border-left-color", "dropdown-background");
    }
  }
}

@media only screen and (max-width: 1024px) {
  #globalSearch {
    z-index: -1000;
    width: 500px;
    margin-top: 15%;
  }

  .main-site-content .global-search .action-sorting {
    margin-top: 125%;
  }

  #globalSearch .infulence-dropdown-list {
    width: 800px !important;
    top: 50px !important;
  }
  .infulence-dropdown-list .btn.btn-outline-primary {
    font-size: 11px !important;
    border-radius: 5px !important;
  }
}

@media only screen and (max-width: 980px) {
  .infulence-dropdown-list {
    width: 700px;
    top: 55px !important;
  }
}

.action-sorting {
  cursor: pointer;

  .sort-icon {
    padding-left: 5px;
  }

  &.active {
    color: $primary-theme-color;
    font-family: $font-grotesk-bold;
  }
}

@media only screen and (max-width: 767px) {
  .infulence-dropdown-list {
    width: 90vw;
    top: 0 !important;
  }
  #globalSearch {
    width: 90vw !important;
  }
  .infulence-dropdown-list .container .row {
    flex-wrap: nowrap !important;
    align-items: center;
  }
  .infulence-dropdown-list .container .row .span {
    overflow: visible !important;
  }
  .account-image .social-icon-img {
    top: 15px;
    right: 15px;
    width: 25px;
    height: 25px;
  }
  .account-image .rounded-circle {
    width: 40px;
    height: 40px;
  }
  .infulence-dropdown-list .btn.btn-outline-primary {
    font-size: 7px !important;
  }
  .infulence-dropdown-list .dropdown-menu {
    font-size: 10px !important;
  }
}

@media only screen and (max-width: 460px) {
  .infulence-dropdown-list {
    width: 85.25vw;
    padding: 0;
  }
  #globalSearch {
    width: 320px !important;
  }
  .infulence-dropdown-list .infulence-dropdown-list-2 {
    padding: 7px;
    .follower-font {
      font-size: 10px;
      color: #818181;
    }
  }
}
.m-15 {
  margin-bottom: 15px;
}

.w-100 {
  width: 100px;
}

.w-150 {
  width: 50px;
}
.text-wrapper {
  background: transparent;
  @include theme-aware("color", "title");
  padding: 55px 30px 50px 55px;
  .page-heading {
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 33px;
  }
  p {
    font-size: 15px;
    line-height: 27px;
  }
}
button.btn.btn-primary.btn-add-tracking {
  background: #20c2e4;
  border-radius: 8px;
  height: 42px;
  width: 128px;
  font-weight: 600;
  font-size: 15px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.375px;
}
.btn-color {
  @include theme-aware("background", "table-background");
  color: #00c2e6;
  padding: 15px;
}
.btn-primary.btn-color.btn-dashed {
  background: transparent;
  border: 1.5px dashed #20c2e4;
  box-sizing: border-box;
  border-radius: 8px;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.325px;
  color: #20c2e4 !important;
  box-shadow: none !important;
}
/*.border-btm {
  border-bottom: 1px solid #e2e9f0;
  padding-bottom: 10px;
}*/
.card.card-custom {
  @include theme-aware("background", "table-background");
  box-shadow: 0px 6px 50px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  border: none;

  .coming-soon {
    display: inline-flex;
    align-items: center;

    background: $danger-button-bg-color;
    opacity: 0.9;
    color: #fff;
    font-size: 14px;

    border: none;
    border-radius: 12px;
    padding: 3px 12px;
  }
}
h3.main-h3 {
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  color: #181818;
  padding-left: 10px;
  padding-top: 20px;
  padding-bottom: 10px;
}

.search-results-wrap .btn-outline-primary {
  color: $primary-theme-color;
  border: $primary-theme-color 1px solid;
  border-radius: 0.25rem;
  background: $color-white;

  max-height: 105px;
  line-height: 34px;
  padding: 0 10px;

  &:hover {
    background: $primary-button-hover-outline-color;
    color: $color-white;
    border: $primary-button-hover-outline-color 1px solid;

    @include box-shadow($primary-button-hover-outline-shadow);
  }

  &:focus,
  &:active,
  &:visited,
  &:not(:disabled):not(.disabled):active {
    @include theme-aware("background", "modal-background");
    color: $primary-theme-color;
    border: $primary-theme-color 1px solid;

    @include box-shadow($primary-button-active-outline-shadow);
  }
}
.search-results-wrap .table-hover tbody tr:hover {
  @include theme-aware("background-color", "hover");
  border-radius: 8px;
}
.story-text {
  h2 {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  svg {
    margin-left: 5px;
    margin-bottom: 2.5px;

    @include theme-aware("color", "title");
  }
}
