.quick-search.react-multi-carousel-list .react-multi-carousel-track {
  height: 200px;
  overflow: hidden;

  padding-bottom: 40px;

  .react-multi-carousel-item .card {
    width: 311px;
    overflow: hidden;

    .card-img-overlay {
      filter: drop-shadow(0px 6px 50px rgba(0, 0, 0, 0.05));
      background: linear-gradient(
        346.12deg,
        rgba(0, 0, 0, 0.6) 26.23%,
        rgba(255, 255, 255, 0) 100%
      );

      &:hover {
        cursor: pointer;
      }

      .card-title {
        font-size: 1rem;
        color: #ffffff;
        position: absolute;
        bottom: 0;
        right: 0;
        margin: 10px 15px;
        max-width: calc(100% - 75px);
      }

      .platform-icon {
        position: absolute;
        bottom: 0;
        left: 0;
        margin: 10px 10px;

        .social-icon-round {
          margin: 0;
        }
      }
    }
  }
}
