// Variables
// =================================
@import "../../color-palette";
@import "../../mixins";

.link-more {
  color: $primary-theme-color;

  &:hover {
    color: $primary-theme-color;
  }
}

// headings
// ==================================

h1,
h2,
h3 {
  font-family: $font-grotesk-bold;
}

// Content
// ==================================

.react-multi-carousel-list {
  margin: 0px 18px;
  padding-top: 10px;

  .react-multi-carousel-track {
    min-height: 200px;

    .react-multi-carousel-item {
      padding: 5px 25px 5px 0px;
      height: 100%;

      .card {
        @include theme-aware("background-color", "table-background");
        @include theme-aware("box-shadow", "shadow");
        border: 0;
        border-radius: 12px;
        height: 100%;
      }

      .card-body {
        padding: 30px;
      }

      .card-title {
        @include theme-aware("color", "title");
      }

      .card-text {
        line-height: 1.4;
      }
    }
  }

  .react-multiple-carousel__arrow {
    position: absolute;
    background: transparent;
    outline: none;

    i {
      font-size: 20px;
      color: $primary-theme-color;
    }

    &.react-multiple-carousel__arrow--left {
      left: -15px;

      &:before {
        content: none;
      }
    }

    &.react-multiple-carousel__arrow--right {
      right: -15px;

      &:before {
        content: none;
      }
    }

    &:hover {
      i {
        color: #000;
      }
    }
  }

  .react-multi-carousel-dot-list {
    .react-multi-carousel-dot--active button {
      background: $primary-theme-color;
      border-color: $primary-theme-color;

      &:focus {
        outline: none;
      }
    }
  }
}

.react-multi-carousel-dot button {
  @include theme-aware("background-color", "dropdown-hover");
  @include theme-aware("border-color", "dropdown-hover");
  border: 2px solid;
}
