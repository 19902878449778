@import "variables";

$themes: (
  light: (
    color-background: #fafbfd,
    modal-background: #fff,
    sidebar-background: #fff,
    table-background: #fff,
    filter-background: #fff,
    dropdown-background: #fff,
    dropdown-hover: darken(#fafbfd, 5%),
    dropdown-disabled: darken(#fafbfd, 15%),
    tooltip-background: darken(#fafbfd, 10%),
    hover: darken(#fafbfd, 2.5%),
    loader: rgba(#fafbfd, 0.75),
    title: #181818,
    icon-text: rgba(#181818, 0.75),
    icon-sidebar: rgba(#181818, 0.65),
    tags-background: $tags-background-color,
    shadow: $blocks-shadow,
    shadow-dark: $blocks-shadow-dark,
    dropdown-shadow: $dropdown-shadow,
    image-shadow: $image-shadow,
    button-shadow: $primary-button-shadow,
    border-color-light: $border-color__light,
    settings-button: url(./img/icon-three-dots.svg) no-repeat 13px 18px #fff,
    border: 1px solid #e2e9f0,
    influencer-icon: url(./img/002-megaphone.svg) no-repeat 0 1px,
    audience-icon: url(./img/001-audience.svg) no-repeat 0 1px,
    caret-icon: url(./img/caret-down.svg) no-repeat 0 0,
    badge-info-color: $color-light-grey,
    badge-success-bg: #ccf6e4,
    badge-success-color: #00864e,
    badge-warning-bg: #fff2ca,
    badge-warning-color: #f2b600,
    badge-danger-bg: #fad7dd,
    badge-danger-color: #932338,
    badge-neutral-bg: #d4f2ff,
    badge-neutral-bg-dark: darken(#d4f2ff, 5%),
    badge-neutral-color: #1978a2,
  ),
  dark: (
    color-background: lighten($dark-mode-background-color, 2.5%),
    modal-background: lighten($dark-mode-background-color, 5%),
    sidebar-background: lighten($dark-mode-background-color, 5%),
    table-background: lighten($dark-mode-background-color, 5%),
    filter-background: lighten($dark-mode-background-color, 7.5%),
    dropdown-background: lighten($dark-mode-background-color, 10%),
    dropdown-hover: lighten($dark-mode-background-color, 12.5%),
    dropdown-disabled: lighten($dark-mode-background-color, 30%),
    tooltip-background: lighten($dark-mode-background-color, 15%),
    hover: lighten($dark-mode-background-color, 7.5%),
    loader: rgba($dark-mode-background-color, 0.75),
    title: rgba(#fafbfd, 0.8),
    icon-text: rgba(#fafbfd, 0.75),
    icon-sidebar: rgba(#fafbfd, 0.65),
    tags-background: lighten($dark-mode-background-color, 7.5%),
    shadow: "none",
    shadow-dark: "none",
    dropdown-shadow: "none",
    image-shadow: "none",
    button-shadow: "none",
    border-color-light: #66666640,
    settings-button: url(./img/icon-three-dots-white.svg) no-repeat 13px 18px
      lighten($dark-mode-background-color, 5%),
    border: 1px solid rgba(#fafbfd, 0.25),
    influencer-icon: url(./img/002-megaphone-white.svg) no-repeat 0 1px,
    audience-icon: url(./img/001-audience-white.svg) no-repeat 0 1px,
    caret-icon: url(./img/caret-down-white.svg) no-repeat 0 0,
    badge-info-color: rgba(#fafbfd, 0.65),
    badge-success-bg: #0b403e,
    badge-success-color: #5ce2aa,
    badge-warning-bg: "",
    badge-warning-color: "",
    badge-danger-bg: #392137,
    badge-danger-color: #ef7f93,
    badge-neutral-bg: #133c58,
    badge-neutral-bg-dark: lighten(#133c58, 5%),
    badge-neutral-color: #75d4fe,
  ),
);
