

.page-graphic {
	background: url(../../img/page-graphic.svg) no-repeat 0 0;
	
}
.active-filters ul {
	margin-bottom: 5px;
}
.active-filters.inside {
	max-width: 660px;
}
.active-filters.inside li {
	cursor: text;
	padding: 5px;
	font-size: 12px;
	margin-bottom: 10px;
	clear: both;
	max-width: 100%;
	width: auto;
	min-height: auto!important;
}

.btn-padding{
	padding: 8px !important; 
	white-space: nowrap; 
	overflow: hidden;
	text-overflow: ellipsis; 
	
.ul-width{
	min-width:14px !important;
}
}

@media only screen and (min-width:768px){
.main-site-content.container {
	min-height: 500px
}

}
