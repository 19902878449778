@import "theme";

// Variables
// =================================

$container-width: 1118px;
$mobile-width: 1024px;

$highchart-theme-blue: #1f87ee;
$highchart-theme-red: #ff5537;

$color-grey: #f3f6fb;
$color-light-grey: #8896a5;
$color-xlight-grey: #b1bdc9;
$color-white: #fff;
$color-black: #000;
$color-green: #30c62e;
$color-red: #ff5537;
$color-blue: #1f87ee;
$grey-disabled: #c8c7c9;
$success-button-bg-color: #30c62e;
$danger-button-bg-color: #f26765;
$warning-button-bg-color: #f5a623;

$dark-mode-background-color: #0e0e10;

$bg-grey: #f3f6fb;

$border-color__light: #d0d0d0;
$tags-background-color: #e1e6ef;

// inputs
$input-border-color: #d0d0d0;
$radio-button-color: #ced4da;

// Fonts
$font-grotesk-Regular: "HKGrotesk-Regular", Arial, "sans-serif";
$font-grotesk-medium: "HKGrotesk-Medium", Arial, "sans-serif";
$font-grotesk-bold: "HKGrotesk-Bold", Arial, "sans-serif";

$header-bg-color: $color-white;
$nav-item-color: $color-black;

$font-size-small: 12px;
$font-size-default: 14px;
$font-size-md: 16px;

// Margin & Padding
$blocks-inside-padding: 20px;

// Shadow
$blocks-shadow: 10px 10px 30px rgba(162, 180, 196, 0.1);
$blocks-shadow-dark: 10px 10px 10px rgba(162, 180, 196, 0.1);
$dropdown-shadow: 10px 50px 50px rgba(0, 0, 0, 0.35);
$image-shadow: 0px 0px 7px rgba(162, 180, 196, 0.5);

// Radius
$blocks-radius: 12px;

// Buttons
$btn-outline-dark: $color-black;

$success-button-bg-color: $success-button-bg-color;
$success-button-shadow: 0px 3px 10px rgba(48, 198, 46, 0.32);

$danger-button-bg-color: $danger-button-bg-color;
$danger-button-shadow: 0px 3px 10px rgba(242, 103, 101, 0.32);

$warning-button-bg-color: $warning-button-bg-color;
//$warning-button-shadow:0px 3px 10px rgba(242, 103, 101, 0.32);

// Headings
$h1-font-size: 18px;
$h2-font-size: 14px;
$h3-font-size: 14px;

// mixins
@mixin box-shadow($property) {
  -webkit-box-shadow: $property;
  -ms-box-shadow: $property;
  box-shadow: $property;
}

@mixin border-radius($radius-property) {
  -webkit-border-radius: $radius-property;
  -ms-border-radius: $radius-property;
  border-radius: $radius-property;
}
