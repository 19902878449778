// Variables
// =================================
@import "../../color-palette";
@import "../../mixins";

.react-multi-carousel-list .react-multi-carousel-track {
  .card-icon {
    color: $color-light-grey;

    svg {
      display: block;
      margin: 20px auto 0px;
    }
  }

  .card-title {
    font-size: 1rem;
  }

  .card-link {
    position: absolute;
    bottom: 0;
    margin-bottom: 25px;
    width: 80%;
  }
}
